import React from "react";
import Container from "./Container";
import { SimpleListItem } from "./ListItems";
import style from "./style.module.scss";
import { history } from "../../_redux";
import { AvatarHeading } from "./Headings";
import { mixpanel } from "components/Mixpanel";
import { Permissions } from "features/Permissions";
import LinkIcon from "assets/images/icons/JSX/Icon-Link";
import UserIcon from "assets/images/icons/JSX/User";
import DownloadIcon from "assets/images/icons/JSX/Icon-Download";
import { CSVLink } from "react-csv";
import { isEmpty } from "lodash";
import { ReactComponent as IconBell } from "assets/images/icons/Icon-Bell.svg";
import { ReactComponent as IconLogout } from "assets/images/icons/Icon-Logout.svg";
import { ReactComponent as IconPlatform } from "assets/images/icons/Icon-Platform.svg";
import { ReactComponent as SettingsIcon } from "assets/images/icons/Icon-Settings-Small.svg";
import { getSelectWorkspaceId } from "_redux/users/selectors";

const navigateToSettings = () => history.push("/dashboard/user/");

export const UserDropdown = ({ username = "", avatarImage, isOpen, role }) => {
    const workspaceId = getSelectWorkspaceId();
    return (
        <Container className={style.userDropdown} isOpen={isOpen}>
            <AvatarHeading username={username} subtitle={role} avatarImage={avatarImage} />
            <div className={style.largeScreenOnly}>
                <hr />
                <SimpleListItem
                    className={`${style.dropdownItem} ${style.userDropdownTopMargin} ${style.userDropdownBottomMargin}`}
                    onClick={() => history.push("/dashboard/profile/settings")}
                >
                    <SettingsIcon />
                    My Settings
                </SimpleListItem>
                {!workspaceId && (
                    <Permissions actions={"ACTION_PLATFORM_SETTINGS"}>
                        <SimpleListItem
                            className={`${style.dropdownItem} ${style.userDropdownTopMargin} ${style.userDropdownBottomMargin}`}
                            onClick={navigateToSettings}
                        >
                            <IconPlatform /> Workspace Settings
                        </SimpleListItem>
                    </Permissions>
                )}
                {role !== "reporting" && (
                    <SimpleListItem
                        className={`${style.dropdownItem} ${style.userDropdownTopMargin} ${style.userDropdownBottomMargin}`}
                        onClick={() => history.push("/dashboard/alert-settings")}
                    >
                        <IconBell /> Alert Settings
                    </SimpleListItem>
                )}
            </div>
            <hr />
            <SimpleListItem
                className={`${style.dropdownItem} ${style.userDropdownTopMargin} ${style.userLogout}`}
                onClick={() => {
                    history.push("/logout");
                }}
            >
                <IconLogout />
                Log Out
            </SimpleListItem>
        </Container>
    );
};

export const AnomalyDropDown = ({ isOpen, onClick, read, onInvestigate }) => (
    <Container className={style.anomalyDropdown} isOpen={isOpen}>
        <SimpleListItem
            className={style.anomalyDropdownListItem}
            onClick={(e) => onInvestigate(e)}
            label="Investigate"
        />
    </Container>
);

export const HelpDropdown = ({ isOpen }) => (
    <Container className={style.helpsDropdown} isOpen={isOpen} dropdownWidth="13.125rem">
        <div className={style.helpsDropdownTitle}>Help &amp; Support</div>
        <hr />
        <SimpleListItem
            onClick={() => {
                mixpanel.track("Button Click", {
                    "Button Text": "Email Support",
                    "Button Name": "Outbound Support Ticket Link",
                });
            }}
            label="Email Support"
        >
            <a
                className={style.link}
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:support@hawke.ai?subject=Hawke AI Support Ticket"
            >
                Email Support
            </a>
        </SimpleListItem>
    </Container>
);

export const EmailTestDropdown = ({ isOpen, onAnomalyTest, onWeeklyTest }) => (
    <Container className={style.emailTestDropdown} isOpen={isOpen}>
        <SimpleListItem className={style.emailTestDropdownListItem} onClick={onWeeklyTest} label="Test Weekly Email" />
        <SimpleListItem
            className={style.emailTestDropdownListItem}
            onClick={onAnomalyTest}
            label="Test Anomaly Email"
        />
    </Container>
);

export const ShareDropdown = ({ isOpen, sharableLinkToggle, dataStudioToggle, navigateToTeamPage, exportPdf }) => (
    <Container className={style.shareDropdown} isOpen={isOpen} dropdownWidth="18rem">
        <SimpleListItem
            onClick={() => sharableLinkToggle()}
            className={`${style.dropDownLink} ${style.shareLink}`}
            label="Shareable Link"
        >
            <LinkIcon />
            <div>
                <h3>Shareable Link & Email Scheduler</h3>
                <p>Get a shareable link to this reporting summary or schedule it as an email.</p>
            </div>
        </SimpleListItem>
        <SimpleListItem
            onClick={navigateToTeamPage}
            className={`${style.dropDownLink} ${style.shareLink}`}
            label="Invite Team Member"
        >
            <UserIcon />
            <div>
                <h3>Invite Team Member</h3>
                <p>Invite a team member to the app.</p>
            </div>
        </SimpleListItem>
        <SimpleListItem
            onClick={exportPdf}
            className={`${style.dropDownLink} ${style.shareLink}`}
            label="Export PDF"
            disabled={!Boolean(exportPdf)}
        >
            <DownloadIcon />
            <div>
                <h3>Export PDF</h3>
                <p>Export report to PDF.</p>
            </div>
        </SimpleListItem>
    </Container>
);

export const ExportDropdown = ({ isOpen, csvData, fileName = "export", exportPdf, isExportingPdf }) => {
    const hasCsv = !isEmpty(csvData);
    return (
        <Container className={style.shareDropdown} isOpen={isOpen}>
            {hasCsv && (
                <CSVLink data={csvData} filename={`${fileName}.csv`}>
                    <SimpleListItem
                        onClick={() => {}}
                        className={`${style.dropDownLink} ${style.shareLink}`}
                        label="Export CSV"
                    >
                        Export as CSV
                    </SimpleListItem>
                </CSVLink>
            )}
            <SimpleListItem
                onClick={exportPdf}
                className={`${style.dropDownLink} ${style.shareLink}`}
                label="Export PDF"
                disabled={isExportingPdf}
            >
                Export as PDF
            </SimpleListItem>
        </Container>
    );
};
