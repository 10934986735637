import React, { useEffect, useState, useRef, useCallback } from "react";
import style from "./style.module.scss";
import throttle from "lodash/throttle";
import { LoadingCircles } from "components/Loading";
import { mainPanelRef } from "components/MainPanel";
import { getComparator, stableSort, MAX_ROWS_COUNT_PER_PAGE } from "./helpers";
import TableFooter from "./TableFooter";
import TableContent from "./TableContent";
import ScrollTableWrapper from "./ScrollTableWrapper";

export default ({
    headCells: unformattedHeadCells,
    withHeaders = true,
    rows = [],
    className,
    rowClass,
    hover = true,
    dividers = true,
    pagination = false,
    shortHeader = false,
    verticalAlign = "center",
    totalsRow,
    defaultSortProp = "",
    defaultSortOrder = null,
    defaultRowsPerPage = 10,
    allowScroll = false,
    rowsPerPageOptions = [10, 25, 50, 100],
    parentScrolled = false,
    activeMetricFilter,
    heatMapColumns = [],
    selected = [],
    setSelected,
    showSelection = false,
    alwaysShowTotalRow = false,
    orderByDataType = "",
    disableLoadingCircles = false,
    stickyHeader = true,
    allowSelectCols = false,
    tableId = "",
    fixedColumns = [],
}) => {
    const [order, setOrder] = useState(null);
    const [orderBy, setOrderBy] = useState(null);
    const [orderPending, setOrderPending] = useState(false);
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [totalActiveMetricDiff, setTotalActiveMetricDiff] = useState(0);
    const [isScrolled, toggleScrolled] = useState(false);
    const [headerOffset, setHeaderOffset] = useState(0);

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

    const scrollRef = useRef();
    const tableRef = useRef();
    const rowsCount = rows.length;
    const hasPagination = pagination && rowsCount > defaultRowsPerPage;

    useEffect(() => {
        const handleScroll = () => {
            if (tableRef.current && stickyHeader) {
                const { top } = tableRef.current.getBoundingClientRect();
                setHeaderOffset(top < 0 ? Math.abs(top) + 64 : 0);
            }
        };

        const panel = mainPanelRef.current;

        if (panel) {
            panel.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (panel) {
                panel.removeEventListener("scroll", handleScroll);
            }
        };
    }, [mainPanelRef]);

    // turn array into object
    const headCells = Array.isArray(unformattedHeadCells)
        ? unformattedHeadCells.reduce((obj, cell) => {
              const { id } = cell;
              return {
                  ...obj,
                  [id]: cell,
              };
          }, {})
        : unformattedHeadCells;

    useEffect(() => {
        const newOrder = defaultSortOrder || 1;
        setOrder(newOrder);
    }, [defaultSortOrder]);

    useEffect(() => {
        if (defaultSortProp) {
            setOrderBy(defaultSortProp);
        }
    }, [defaultSortProp]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 1;
        setOrder(isAsc ? -1 : 1);
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((row) => row.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        switch (true) {
            case selectedIndex === -1:
                newSelected = newSelected.concat(selected, id);
                break;
            case selectedIndex === 0:
                newSelected = newSelected.concat(selected.slice(1));
                break;
            case selectedIndex === selected.length - 1:
                newSelected = newSelected.concat(selected.slice(0, -1));
                break;
            case selectedIndex > 0:
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
                break;
            default:
                newSelected = selected;
                break;
        }

        setSelected(newSelected);
    };

    const handleScroll = useCallback(
        throttle(
            () => {
                const tableLeftPos = tableRef.current?.getBoundingClientRect().left;
                const scrollWrapperLeftPos = scrollRef.current?.getBoundingClientRect().left;

                if (tableLeftPos < scrollWrapperLeftPos) {
                    toggleScrolled(true);
                } else {
                    toggleScrolled(false);
                }
            },
            500,
            { leading: true },
        ),
        [tableRef, scrollRef],
    );

    useEffect(() => {
        if (scrollRef && activeMetricFilter?.id) {
            try {
                const index = unformattedHeadCells.findIndex(({ id } = {}) => id === activeMetricFilter?.id);
                const percentage = index / unformattedHeadCells.length;
                const newScrollPosition = percentage * scrollRef.current.scrollWidth - 250 - 150; // 250 is the campaign name width plus a column offset
                scrollRef.current.scrollLeft = newScrollPosition;
            } catch (e) {
                console.log(e);
            }
        }
    }, [activeMetricFilter?.id]);

    useEffect(() => {
        setOrderPending(true);
        setTimeout(() => {
            const orderFunc =
                headCells[orderBy] && headCells[orderBy].sort
                    ? headCells[orderBy].sort(order, orderBy)
                    : getComparator(order, orderBy, orderByDataType);

            const sortedRows = stableSort(rows, orderFunc);

            setPaginatedRows(
                pagination && rowsPerPage > 0
                    ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : sortedRows,
            );

            setTotalActiveMetricDiff(
                activeMetricFilter?.id
                    ? paginatedRows.reduce(
                          (sum, { [activeMetricFilter?.id]: { value = 0, oldValue = 0 } = {} } = {}) => {
                              const clean = (v) => (isNaN(v) ? 0 : v);
                              return sum + clean(value) - clean(oldValue);
                          },
                          0,
                      )
                    : 0,
            );
            setOrderPending(false);
        }, 0);
    }, [rows, order, orderBy, orderByDataType, page, rowsPerPage, activeMetricFilter?.id]);

    if (rowsCount > MAX_ROWS_COUNT_PER_PAGE && orderPending && !disableLoadingCircles) {
        return (
            <div className={style.loadingWrapper}>
                <LoadingCircles />
            </div>
        );
    }

    const tableContentProps = {
        withHeaders,
        rowsCount,
        className,
        rowClass,
        hover,
        dividers,
        pagination,
        shortHeader,
        verticalAlign,
        totalsRow,
        allowScroll,
        parentScrolled,
        activeMetricFilter,
        heatMapColumns,
        selected,
        showSelection,
        alwaysShowTotalRow,
        page,
        rowsPerPage,
        isScrolled,
        tableRef,
        paginatedRows,
        headCells,
        order,
        orderBy,
        handleRequestSort,
        handleSelectAllClick,
        headerOffset,
        totalActiveMetricDiff,
        handleClick,
        allowSelectCols,
        tableId,
        fixedColumns,
    };

    return (
        <>
            {allowScroll ? (
                <ScrollTableWrapper
                    scrollRef={scrollRef}
                    handleScroll={handleScroll}
                    currentRowsCount={paginatedRows.length}
                    isPaginated={hasPagination}
                >
                    <TableContent {...tableContentProps} />
                </ScrollTableWrapper>
            ) : (
                <TableContent {...tableContentProps} />
            )}

            {hasPagination && (
                <TableFooter
                    allowScroll={allowScroll}
                    rowsCount={rowsCount}
                    page={page}
                    onChangePage={setPage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={setRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                />
            )}
        </>
    );
};
