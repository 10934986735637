import React, { useState } from "react";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import DraggableContainer from "./DraggableContainer";
import { isEmpty } from "lodash";
import ColumnItem from "./ColumnItem";
import style from "./style.module.scss";

const SelectedColumns = ({ selectedColumns = [], onColumnToggle, onChangeColumns }) => {
    const [activeId, setActiveId] = useState(null);

    const handleDragEnd = ({ active, over }) => {
        if (over && active.id !== over?.id) {
            const oldIndex = selectedColumns.findIndex((item) => item.id === active.id);
            const newIndex = selectedColumns.findIndex((item) => item.id === over.id);

            onChangeColumns(arrayMove(selectedColumns, oldIndex, newIndex));
        }

        setActiveId(null);
    };

    return (
        <DndContext
            onDragStart={({ active }) => {
                setActiveId(active.id);
            }}
            onDragEnd={handleDragEnd}
            onDragCancel={() => setActiveId(null)}
        >
            <div className={style.selectedMetrics}>
                <span className={style.selectText}>Selected Columns</span>
                <SortableContext items={selectedColumns}>
                    <div className={style.listMetrics}>
                        {isEmpty(selectedColumns) ? (
                            <span className={style.emptyMessage}>Please select at least one column</span>
                        ) : (
                            selectedColumns.map((column) => (
                                <DraggableContainer
                                    key={column.id}
                                    id={column.id}
                                    render={(dragProps) => (
                                        <ColumnItem
                                            {...dragProps}
                                            column={column}
                                            onDelete={() => onColumnToggle({ option: column, isChecked: false })}
                                        />
                                    )}
                                />
                            ))
                        )}
                    </div>
                </SortableContext>
            </div>
            <DragOverlay
                dropAnimation={{
                    duration: 250,
                    easing: "ease",
                }}
            >
                {activeId && (
                    <ColumnItem isDragOverlay column={selectedColumns.find((column) => column.id === activeId)} />
                )}
            </DragOverlay>
        </DndContext>
    );
};

export default SelectedColumns;
