import React from "react";
import style from "./style.module.scss";
import Avatar from "components/Avatar";
import WarningIcon from "../../../assets/images/icons/checkmark-icon.svg";

export default ({ children, className }) => (
    <div className={`${style.SuccessWrapper} ${className && className}`}>
        <Avatar className={style.alertAvatar} size={24} src={WarningIcon} />
        <p>{children}</p>
    </div>
);
