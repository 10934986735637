import React from "react";
import style from "./style.module.scss";
import { pure } from "recompose";
import { Dialog, Grow, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { isEmpty } from "lodash";
import classNames from "classnames";

export const CloseDialog = ({ closeActions }) => (
    <div className={`Close-Dialog ${style.close}`} onClick={() => closeActions()}>
        <div>
            <span>close</span>
        </div>
    </div>
);

const getClasses = ({ rootClass = "", paperClass = "", contentClass = "" }) => ({
    root: `${style.dialogRoot} ${rootClass ? rootClass : ""}`,
    paper: `${style.paper} ${paperClass ? paperClass : ""}`,
});

export const DialogVFour = pure(({ children, close, classes = {}, ...props }) => (
    <Dialog classes={getClasses(classes)} TransitionComponent={Grow} transitionDuration={450} {...props}>
        {children}
    </Dialog>
));

export default pure(
    ({
        children,
        open,
        onRequestClose,
        className,
        parentClassname,
        contentClassName,
        actionsClassName,
        title,
        actions,
    }) => (
        <Dialog
            open={open}
            classes={{
                root: classNames(style.customDialog, { [parentClassname]: parentClassname }),
                paper: classNames(style.dialogPaper, { [className]: className }),
            }}
            onClose={onRequestClose}
        >
            {title && <DialogTitle>{title}</DialogTitle>}
            {children && (
                <DialogContent
                    classes={{
                        root: classNames(style.dialogContent, { [contentClassName]: contentClassName }),
                    }}
                >
                    {children}
                </DialogContent>
            )}
            {!isEmpty(actions) && (
                <DialogActions
                    classes={{ root: classNames(style.dialogFooter, { [actionsClassName]: actionsClassName }) }}
                >
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    ),
);
