import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";
import { get, upperFirst } from "lodash";

export const defaultState = {
    multipleReports: {},
    bingTopTermsReport: {},
    bingTopTermsReportLoading: false,
    bingTopTermsReportError: false,
    showCardSettingsSidebar: false,
    editingCard: {},
    tabIndexSidebar: 0,
    sidebarIntegration: {},
    sidebarCardType: "kpi",
    sidebarSearchInput: "",
    needNewReportData: null,
    showReportSettingsSidebar: false,
    multipleReportsLoading: false,
    multipleReportsError: false,
    currentUpdatingReportId: "",
    isInvalidReport: false,
};

let benchmarkReducers = {};

Object.keys(types.GET_REPORT_BUILDER_BENCHMARK_DATA).forEach((platform) => {
    Object.keys(types.GET_REPORT_BUILDER_BENCHMARK_DATA[platform]).forEach((benchmarkType) => {
        const benchmarkTypeAction = types.GET_REPORT_BUILDER_BENCHMARK_DATA[platform][benchmarkType];
        const benchmarkTypeKey = upperFirst(benchmarkType.split("-")[1] || "");
        const benchmarkTypeLoadingKey = `${platform}${benchmarkTypeKey}BenchmarkLoading`;
        const benchmarkTypeErrorKey = `${platform}${benchmarkTypeKey}BenchmarkError`;
        const benchmarkTypeDataKey = `${platform}${benchmarkTypeKey}BenchmarkReport`;
        benchmarkReducers = {
            ...benchmarkReducers,
            [benchmarkTypeAction]: (state, action) => {
                return handle(state, action, {
                    start: (prevState) => ({
                        ...prevState,
                        [benchmarkTypeLoadingKey]: true,
                    }),
                    success: (prevState) => {
                        const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                        const { payload = {} } = action || {};
                        const benchmarkData = isCancelledReq ? prevState[benchmarkTypeDataKey] : { ...payload };

                        return {
                            ...prevState,
                            [benchmarkTypeLoadingKey]: isCancelledReq ? true : false,
                            [benchmarkTypeErrorKey]: false,
                            [benchmarkTypeDataKey]: benchmarkData,
                        };
                    },
                    failure: (prevState) => ({
                        ...prevState,
                        [benchmarkTypeLoadingKey]: false,
                        [benchmarkTypeErrorKey]: true,
                    }),
                });
            },
        };
    });
});

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        ...benchmarkReducers,
        [types.GET_REPORT_BING_TOP_TERMS_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    bingTopTermsReportLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { payload = {} } = action;
                    const bingTopTermsReport = isCancelledReq ? prevState.bingTopTermsReport : { ...payload };

                    return {
                        ...prevState,
                        bingTopTermsReportLoading: isCancelledReq ? true : false,
                        bingTopTermsReportError: false,
                        bingTopTermsReport,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    bingTopTermsReportLoading: false,
                    bingTopTermsReportError: true,
                }),
            });
        },
        [types.SET_SHOW_CARD_SETTINGS_SIDEBAR]: (state, action) => {
            return {
                ...state,
                showCardSettingsSidebar: action.payload,
                showReportSettingsSidebar: false,
            };
        },
        [types.SET_EDITING_CARD]: (state, action) => {
            return {
                ...state,
                editingCard: action.payload,
            };
        },
        [types.SET_TAB_INDEX_SIDEBAR]: (state, action) => {
            return {
                ...state,
                tabIndexSidebar: action.payload,
            };
        },
        [types.SET_SIDEBAR_INTEGRATION]: (state, action) => {
            return {
                ...state,
                sidebarIntegration: action.payload,
            };
        },
        [types.SET_SIDEBAR_CARD_TYPE]: (state, action) => {
            return {
                ...state,
                sidebarCardType: action.payload,
            };
        },
        [types.SET_SIDEBAR_SEARCH_INPUT]: (state, action) => {
            return {
                ...state,
                sidebarSearchInput: action.payload,
            };
        },
        [types.GET_ACCOUNT_MULTIPLE_REPORTS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    multipleReportsLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { payload = {} } = action;
                    const multipleReports = isCancelledReq ? prevState.multipleReports : { ...payload };

                    return {
                        ...prevState,
                        multipleReportsLoading: isCancelledReq ? true : false,
                        multipleReportsError: false,
                        multipleReports,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    multipleReportsLoading: false,
                    multipleReportsError: true,
                }),
            });
        },
        [types.SET_ACCOUNT_CURRENT_REPORT]: (state, action) => {
            const { reportId } = action.payload || {};
            const report = state.multipleReports?.[reportId];

            if (report) {
                return {
                    ...state,
                    multipleReports: {
                        ...state.multipleReports,
                        [reportId]: { ...report, updatedAt: new Date() },
                    },

                    isInvalidReport: false,
                };
            }
            return { ...state, isInvalidReport: true };
        },
        [types.UPDATE_ACCOUNT_REPORT_SETTINGS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { payload = {}, meta = {} } = action;
                    const { reportId } = meta;
                    const multipleReports = isCancelledReq
                        ? prevState.multipleReports
                        : { ...prevState.multipleReports, [reportId]: payload };

                    return {
                        ...prevState,
                        multipleReports,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                }),
            });
        },
        [types.RENAME_ACCOUNT_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { payload = {}, meta = {} } = action;
                    const { reportId } = meta;
                    const multipleReports = isCancelledReq
                        ? prevState.multipleReports
                        : { ...prevState.multipleReports, [reportId]: payload };

                    return {
                        ...prevState,
                        multipleReports,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                }),
            });
        },
        [types.SET_LOAD_REPORT_DATA]: (state, action) => ({
            ...state,
            needNewReportData: action.payload.needNewReportData,
        }),
        [types.SET_SHOW_REPORT_SETTINGS_SIDEBAR]: (state, action) => {
            return {
                ...state,
                showReportSettingsSidebar: action.payload,
                showCardSettingsSidebar: false,
            };
        },
        [types.DELETE_ACCOUNT_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => {
                    const { meta = {} } = action;
                    const { reportId } = meta;

                    return {
                        ...prevState,
                        currentUpdatingReportId: reportId,
                    };
                },
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { meta = {} } = action;
                    const { reportId } = meta;
                    const { [reportId]: deletedAccountReport, ...newMultipleReports } = prevState.multipleReports;

                    return {
                        ...prevState,
                        currentUpdatingReportId: isCancelledReq ? reportId : "",
                        accountReportsError: false,
                        multipleReports: isCancelledReq ? prevState.multipleReports : newMultipleReports,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    currentUpdatingReportId: "",
                    accountReportsError: true,
                }),
            });
        },
        [types.DUPLICATE_ACCOUNT_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    currentUpdatingReportId: "duplicating",
                    multipleReports: { ...prevState.multipleReports, duplicating: { id: "duplicating" } },
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { payload = {} } = action;
                    const multipleReports = isCancelledReq
                        ? prevState.multipleReports
                        : { ...prevState.multipleReports, [payload.id]: payload };
                    const { duplicating: deletedAccountReport, ...newMultipleReports } = multipleReports;

                    return {
                        ...prevState,
                        currentUpdatingReportId: isCancelledReq ? "duplicating" : "",
                        accountReportsError: false,
                        multipleReports: newMultipleReports,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    currentUpdatingReportId: "",
                    accountReportsError: true,
                }),
            });
        },
        [types.CREATE_EMPTY_ACCOUNT_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { payload = {} } = action;
                    const multipleReports = isCancelledReq
                        ? prevState.multipleReports
                        : { ...prevState.multipleReports, [payload.id]: payload };

                    return {
                        ...prevState,
                        multipleReports,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                }),
            });
        },
        [types.CLEAR_REPORT_BUILDER_DATA_LOADING]: (state, action) => {
            return {
                ...state,
                bingTopTermsReportLoading: null,
                adwordsNoneBenchmarkLoading: null,
                adwordsMediaSpendBenchmarkLoading: null,
                adwordsAovBenchmarkLoading: null,
                adwordsLocationBenchmarkLoading: null,
                facebookNoneBenchmarkLoading: null,
                facebookMediaSpendBenchmarkLoading: null,
                facebookAovBenchmarkLoading: null,
                facebookLocationBenchmarkLoading: null,
                shopifyNoneBenchmarkLoading: null,
                shopifyMediaSpendBenchmarkLoading: null,
                shopifyAovBenchmarkLoading: null,
                shopifyLocationBenchmarkLoading: null,
                bingNoneBenchmarkLoading: null,
                bingMediaSpendBenchmarkLoading: null,
                bingAovBenchmarkLoading: null,
                bingLocationBenchmarkLoading: null,
                analyticsV4NoneBenchmarkLoading: null,
                analyticsV4MediaSpendBenchmarkLoading: null,
                analyticsV4AovBenchmarkLoading: null,
                analyticsV4LocationBenchmarkLoading: null,
                tiktokNoneBenchmarkLoading: null,
                tiktokMediaSpendBenchmarkLoading: null,
                tiktokAovBenchmarkLoading: null,
                tiktokLocationBenchmarkLoading: null,
                pinterestNoneBenchmarkLoading: null,
                pinterestMediaSpendBenchmarkLoading: null,
                pinterestAovBenchmarkLoading: null,
                pinterestLocationBenchmarkLoading: null,
            };
        },
    }),
);
