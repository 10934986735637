import types from "./types";
import { MorphioAPI } from "../../../../lib/api";
import { KpiAPI } from "lib/apiKpi";
import moment from "moment";
import { getSelectWorkspaceId } from "_redux/users/selectors";

const initNotifiations = ({ userId }) => (dispatch, getState) => {
    const workspaceId = getSelectWorkspaceId();
    return dispatch({
        type: types.INITIALIZE_NOTIFICATIONS,
        promise: MorphioAPI({
            method: "GET",
            url: `/user/notification`,
            query: {
                userId: workspaceId || userId,
            },
        }),
    });
};

export const getNotifications = ({ userId, abortController }) => {
    const workspaceId = getSelectWorkspaceId();
    return {
        type: types.INITIALIZE_NOTIFICATIONS,
        promise: KpiAPI({
            method: "GET",
            url: `/user/alerts`,
            abortController,
            query: {
                userId: workspaceId || userId,
                startDate: moment()
                    .subtract(1, "days")
                    .format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            },
        }).then(({ data }) => data),
    };
};

const clearCount = ({ userId }) => (dispatch, getState) => {
    return dispatch({
        type: types.CLEAR_COUNT,
        promise: MorphioAPI({
            method: "DELETE",
            url: `/user/notification/unseen`,
            query: {
                userId,
            },
        }),
    });
};

const readNotification = ({ userId, notificationId }) => (dispatch, getState) => {
    return dispatch({
        type: types.READ_NOTIFICATION,
        promise: MorphioAPI({
            method: "PUT",
            url: "/user/notification/read",
            data: {
                userId,
                id: notificationId,
            },
        }),
    });
};

const deleteNotification = ({ userId, notificationId }) => (dispatch, getState) => {
    return dispatch({
        type: types.DELETE_NOTIFICATION,
        promise: MorphioAPI({
            method: "DELETE",
            url: `/user/notification`,
            data: {
                userId,
                id: notificationId,
            },
        }),
    });
};

export default {
    initNotifiations,
    clearCount,
    readNotification,
    deleteNotification,
};
