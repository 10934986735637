import { get, isEmpty } from "lodash";
import { getTrend, getTrendRgbaColor } from "lib/utility";

export const MAX_ROWS_COUNT_PER_PAGE = 100;

const comparator = (dataKey, order) => (a, b) => {
    const av = get(a, [dataKey], null);
    const bv = get(b, [dataKey], null);

    if (av === null) {
        return 1;
    }

    if (bv === null) {
        return -1;
    }

    if (av === bv) {
        return 0;
    }

    // high to low
    if (order === 1) {
        return bv < av ? -1 : 1;
    }

    // low to high
    return av < bv ? -1 : 1;
};

export const getComparator = (order, orderBy, orderByDataType) => {
    const comparisonDatakey = orderByDataType === "growth" ? "growth" : "value";
    return (a, b) => comparator(comparisonDatakey, order)(a[orderBy], b[orderBy]);
};

export const stableSort = (array, comparator) => {
    return array
        .map((el, index) => [el, index])
        .sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        })
        .map((el) => el[0]);
};

const getHeatMapTrend = ({ value, threshold, trend }) => {
    if (trend === "moreBetter") {
        return value >= threshold ? "positive" : "negative";
    }

    return value >= threshold ? "negative" : "positive";
};

const getHeatMapSettings = ({ heatMapColumns = [], row = {}, paginatedRows = [] }) => {
    if (isEmpty(heatMapColumns)) {
        return {};
    }

    return heatMapColumns.reduce((cache, { id: columnId, threshold = 0, trend = "moreBetter" }) => {
        const rowValue = row[columnId]?.value || 0;
        const total = paginatedRows.reduce((sum, item) => sum + item[columnId]?.value, 0);
        const opacity = rowValue / total;
        const color = getTrend(getHeatMapTrend({ value: rowValue, threshold, trend }));

        return {
            ...cache,
            [columnId]: { color, opacity },
        };
    }, {});
};

export const getRowSettings = ({
    row = {},
    paginatedRows = [],
    activeMetricFilter = {},
    totalActiveMetricDiff,
    heatMapColumns = [],
    selectedColumns = [],
}) => {
    if (isEmpty(row)) {
        return {};
    }

    const { id, rowIndex } = row || {};
    const filteredMetric = activeMetricFilter?.id || "";
    const { value = 0, oldValue = 0 } = row[filteredMetric] || {};

    const trend = getTrend({ number: value - oldValue, metric: filteredMetric });
    const color = getTrendRgbaColor(trend);
    const opacity = 0.8 * (0.2 + (0.9 * Math.abs(value - oldValue)) / Math.abs(totalActiveMetricDiff));
    const heatMapSettings = getHeatMapSettings({ heatMapColumns, row, paginatedRows });
    const isItemSelected = selectedColumns.indexOf(id) !== -1;
    const isLastRow = paginatedRows.length === rowIndex + 1;

    return {
        key: `row_${id || rowIndex}`,
        heatMapSettings,
        isItemSelected,
        isLastRow,
        activeMetricFilter: { ...activeMetricFilter, color, opacity },
    };
};

export const COLUMN_POSITIONS = {
    START: "START",
    END: "END",
};

export const getValidHeadCells = ({ headCells = {}, columnsSetting = [], fixedColumns = [] }) => {
    if (isEmpty(columnsSetting)) {
        return headCells;
    }

    const orderedColumns = [...columnsSetting].sort((a, b) => a.order - b.order);

    fixedColumns.forEach(({ id, order }) => {
        if (order === COLUMN_POSITIONS.START) {
            orderedColumns.unshift({ id });
        }
        return orderedColumns.push({ id });
    });

    return Object.fromEntries(orderedColumns.map(({ id }) => [id, headCells[id]]));
};

export const convertWidth = (width) => {
    if (!width) {
        return null;
    }

    if (typeof width === "number") {
        return width || 0;
    }
    const parsedWidth = parseFloat(width);

    const pxMatch = (width || "").endsWith("px") ? parsedWidth : null;
    const remMatch = (width || "").endsWith("rem") ? parsedWidth * 16 : null;

    return pxMatch ?? remMatch ?? parsedWidth;
};

export const getTableCellWidth = ({ width = 0, allowSelectCols = false }) => {
    if ((width || "").includes("%")) {
        return width;
    }

    return convertWidth(width) + (allowSelectCols ? 24 : 0) || "auto";
};
