import { makeSelectUserId, makeSelectWorkspaces } from "_redux/users/selectors";
import { connect } from "react-redux";
import Component from ".";
import { updateSelectedWorkspace } from "_redux/users";
import { getSites } from "features/ControlPanel/ducks/operations";

const mapStateToProps = (state) => {
    return {
        userId: makeSelectUserId()(state),
        workspaces: makeSelectWorkspaces()(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateSelectedWorkspace: (data) => dispatch(updateSelectedWorkspace(data)),
    getSites: (userId, workspace_id) => dispatch(getSites(userId, workspace_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
