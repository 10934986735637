import React from "react";
import { branch, compose, lifecycle, renderComponent, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import styles from "./style.css";
import Warning from "components/WarningCard";

function reportFeedback(e) {
    e.preventDefault();
    window.location.href = "mailto:support@hawke.ai";
}

const mapStateToProps = ({ user: { user } }) => ({
    user,
});

export const ErrorFragment = ({ onReportFeedback }) => (
    <div className={styles.errorFragment}>
        <Warning
            title={"An error has occurred."}
            subtitle={
                <>
                    <p>
                        Sorry, something went wrong. Please try again, or refresh the page. If you keep seeing this
                        message, please report this issue to us.
                    </p>
                    <br />
                </>
            }
            buttons={[
                {
                    label: "Report Issue",
                    onClick: onReportFeedback,
                },
                {
                    label: "Get me out of here",
                    onClick: () => {
                        window.location.href = "/";
                    },
                },
            ]}
        />
    </div>
);

const withLifecycle = lifecycle({
    componentDidCatch(error, info) {
        const {
            props: { setError, user },
        } = this;

        setError(error);
    },
});

export default (ErrorView = ErrorFragment) =>
    compose(
        connect(mapStateToProps),
        withState("error", "setError", null),
        withHandlers({
            reportFeedback: (props) => reportFeedback,
        }),
        withLifecycle,
        branch(
            ({ error }) => error !== null,
            renderComponent(({ reportFeedback, error }) => {
                return <ErrorView onReportFeedback={reportFeedback} />;
            }),
        ),
    );
