import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import styles from "./style.module.scss";
import React from "react";

const DraggableContainer = ({ render, id }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id,
        transition: {
            duration: 350,
            easing: "ease",
        },
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    const classes = classNames(styles.draggableItem, {
        [styles.isDragging]: isDragging,
    });

    return (
        <div style={style} ref={setNodeRef} className={classes}>
            {render({
                attributes,
                listeners,
                isDragging,
            })}
        </div>
    );
};

export default DraggableContainer;
