import React from "react";
import ArrowButton from "components/Button/ArrowButton";
import ThreeDots from "components/Loading/ThreeDots";
import style from "../style.module.scss";
import classNames from "classnames";

const DropDownButton = ({ title, img, dropDownIsOpen, toggleDropDown, isLoading, maxWidth }) => {
    const classes = classNames(style.selectionArrow, {
        [style.open]: dropDownIsOpen,
    });

    return (
        <div onClick={toggleDropDown} className={classes} style={{ maxWidth: maxWidth ? maxWidth : "100%" }}>
            {isLoading ? (
                <ThreeDots color="#1b264f" className={style.iconLoading} />
            ) : (
                <div className={style.titleWrapper}>
                    {img && typeof img === "string" && (
                        <div
                            className={style.img}
                            style={{ backgroundImage: `url(${img}), url("~assets/images/Icon-NoLandingPages.svg")` }}
                        ></div>
                    )}
                    {img && typeof img === "object" && (
                        <div style={{ marginRight: "0.5rem", marginTop: "0.25rem" }}>{img}</div>
                    )}
                    <h1 className={style.title}>{title}</h1>
                    <ArrowButton className={style.selectionArrowButton} />
                </div>
            )}
        </div>
    );
};

export default DropDownButton;
