import React from "react";
import style from "./style.module.scss";
import ZeroState from "components/ZeroStates/Icon";
import { history } from "_redux";
import { matchPath } from "react-router-dom";
import { isEmpty } from "lodash";
import Button from "components/Button";

const DISABLED_PATH = ["/dashboard", "/dashboard/:siteId", "/dashboard/:siteId/analysis"];

export default ({ fallback: Fallback = ZeroState, title, message, icon, children }) => {
    const ua = navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(ua);
    const isDisabled = DISABLED_PATH.some(
        (path) =>
            !isEmpty(
                matchPath(history.location.pathname, {
                    path: path,
                    exact: true,
                }),
            ),
    );

    return (
        <>
            {isMobile && !isDisabled ? (
                <Fallback containerClass={style.center} title={title} message={message} icon={icon}>
                    <Button onClick={() => history.push("/dashboard")}>Go Back</Button>
                </Fallback>
            ) : (
                children
            )}
        </>
    );
};
