import React from "react";
import styles from "./style.css";
import { branch, renderComponent, compose } from "recompose";
import { Paper } from "@material-ui/core";
import Loading from "../Loading";
import WarningCard from "../WarningCard";

const ZeroState = () => (
    <Paper classes={{ root: styles.requestState }}>
        <WarningCard title="No content!" subtitle="No Content to display at this time." />
    </Paper>
);

const ErrorState = ({
    error: {
        title = "Something Went Wrong!",
        subtitle = "The API request to one of your connected platforms has failed, please refresh and try again. If the issue persists please contact support.",
        buttons = [
            {
                label: "Contact Support",
                onClick: () => window.open("mailto:support@hawke.ai"),
            },
            {
                label: "Reload",
                onClick: () => window.location.reload(),
            },
        ],
    } = {},
}) => (
    <div className={styles.requestState}>
        <WarningCard title={title} subtitle={subtitle} buttons={buttons} />
    </div>
);

const PendingState = ({ pending: { className = "" } = {} }) => (
    <Loading className={className} message="🧠 Crunching the numbers and uncovering insights... " title="" />
);

const zeroStateIfNoData = branch(
    ({ zeroState, ...props }) => (typeof zeroState === "function" ? zeroState(props) : zeroState || false),
    renderComponent(ZeroState),
);

const errorIfExists = branch(({ errorState }) => errorState, renderComponent(ErrorState));

const loaderIfPending = branch(({ pendingState }) => pendingState, renderComponent(PendingState));

export default compose(loaderIfPending, errorIfExists, zeroStateIfNoData);
