import React, { useState } from "react";
import style from "./style.module.scss";
import TableWrapper from "components/Table/MaterialUITable/TableWrapper";
import MaterialUITable from "components/Table/MaterialUITable";
import { getBudgetPlannerSubTableRow, subTableHeadCells, tableHeadCells } from "./helpers";
import PlatfomTableCell from "./PlatfomTableCell";
import { operations } from "features/Dashboard/_ducks";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectAccountId, makeSelectSavedGoalValues, makeSelectSavedGoals } from "_redux/sites/selectors";

const { saveGoals } = operations;

const BudgetPlannerTable = ({ months, metric, secondaryMetric, budgetPlannerData, platforms, totalRow, showOnly }) => {
    const dispatch = useDispatch();
    const saveGoalsData = (v) => dispatch(saveGoals(v));
    const metrics = useSelector((state) => makeSelectSavedGoals()(state));
    const goals = useSelector((state) => makeSelectSavedGoalValues()(state));
    const accountId = useSelector((state) => makeSelectAccountId()(state));
    const [budgetSavePending, setBudgetSavePending] = useState({});

    return (
        <TableWrapper className={style.tableWrapper}>
            <MaterialUITable
                allowScroll={true}
                headCells={tableHeadCells(months)}
                pagination={true}
                defaultRowsPerPage={10}
                rowClass={style.rowClass}
                rows={[
                    totalRow,
                    ...Object.keys(platforms).map((platform) => {
                        const { industryBenchmarksSpend: { min: costMin, max: costMax } = {} } =
                            budgetPlannerData?.[months[0]]?.find((item) => item.platform === platform) || {};
                        return {
                            id: platform,
                            platform: {
                                content: (
                                    <PlatfomTableCell
                                        platform={platform}
                                        metric={metric}
                                        secondaryMetric={secondaryMetric}
                                        showOnly={showOnly}
                                        costMin={costMin}
                                        costMax={costMax}
                                    />
                                ),
                                value: platform,
                                verticalAlign: "top",
                            },
                            ...months.reduce((acc, month) => {
                                const {
                                    budget,
                                    metricValue,
                                    secondaryMetricValue,
                                    forecasted: {
                                        cost: originalForecastedBudget,
                                        metricValue: originalForecastedValue,
                                        secondaryMetricValue: originalForecastedSecondaryValue,
                                    } = {},
                                    industryBenchmark: { min, max } = {},
                                } = budgetPlannerData?.[month]?.find((item) => item.platform === platform) || {};

                                let totalBudget = 0;
                                (budgetPlannerData?.[month] || []).forEach((item) => {
                                    totalBudget += item?.budget;
                                });

                                return {
                                    ...acc,
                                    [month]: {
                                        content: (
                                            <TableWrapper className={style.subTableContainer}>
                                                <MaterialUITable
                                                    headCells={subTableHeadCells(month)}
                                                    withHeaders={false}
                                                    rowClass={style.rowClassSubTable}
                                                    rows={getBudgetPlannerSubTableRow({
                                                        month,
                                                        metric,
                                                        secondaryMetric,
                                                        metricValue,
                                                        secondaryMetricValue,
                                                        originalForecastedValue,
                                                        originalForecastedSecondaryValue,
                                                        min,
                                                        max,
                                                        budget,
                                                        originalForecastedBudget,
                                                        showOnly,
                                                        totalBudget,
                                                        saveGoalsData,
                                                        metrics,
                                                        goals,
                                                        platform,
                                                        accountId,
                                                        budgetSavePending,
                                                        setBudgetSavePending,
                                                    })}
                                                />
                                            </TableWrapper>
                                        ),
                                        value: budget,
                                        verticalAlign: "top",
                                    },
                                };
                            }, {}),
                        };
                    }),
                ]}
            />
        </TableWrapper>
    );
};

export default BudgetPlannerTable;
