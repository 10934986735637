import { default as classnames } from "classnames";
import DropDownContainer from "components/DropDown/Container";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import React, { useCallback, useEffect, useState } from "react";
import style from "../style.module.scss";
import SearchBox from "components/SearchBox";
import DropDownButton from "../AccountSelection/DropDownButton";
import { SimpleListItem } from "components/DropDown/ListItems";
import { ReactComponent as ClientOverviewIcon } from "assets/images/icons/Icon-PerformanceOverview.svg";
import { history } from "_redux";
import LazyLoad from "react-lazyload";

const WorkspaceSelector = ({ getSites, userId, workspaces = [], updateSelectedWorkspace }) => {
    const [dropDownIsOpen, toggleDropDown] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [selectedWorkspace, setSelectedWorkspace] = useState({});
    const [workspacesFiltered, setWorkspacesFiltered] = useState([]);
    const ref = React.useRef();

    const reference = useHandleOutsideClickHook(() => {
        toggleDropDown(false);
    });

    useEffect(() => {
        const temp = Object.keys(workspaces)
            .map((id) => ({
                id,
                ...workspaces[id],
            }))
            .filter(({ name }) => !searchInput || name.toLowerCase().includes(searchInput.toLowerCase()));

        setWorkspacesFiltered(temp);
    }, [workspaces, searchInput]);

    useEffect(() => {
        if (workspacesFiltered.length) {
            setSelectedWorkspace(workspacesFiltered.find(({ selected }) => selected) || workspacesFiltered[0]);
        }
    }, [workspacesFiltered]);

    useEffect(() => {
        //check local storage for selected workspace
        const workspaceId = localStorage.getItem("selectedWorkspace");

        if (workspaceId) {
            updateSelectedWorkspace({ workspaceId });
            getSites(userId, workspaceId);
        }
    }, [updateSelectedWorkspace, getSites, userId]);

    const handleToggleDropDown = useCallback(() => {
        if (!dropDownIsOpen) {
            //Wait until animation is finished and component is loaded
            setTimeout(() => ref.current && ref.current.focus(), 100);
        }

        toggleDropDown(!dropDownIsOpen);
    }, [dropDownIsOpen, ref]);

    const handleSelectWorkspace = useCallback(
        (workspaceId) => (e) => {
            toggleDropDown(false);
            // store selected workspace in local storage
            localStorage.setItem("selectedWorkspace", workspaceId);

            window.location = "/dashboard";
        },
        [toggleDropDown, updateSelectedWorkspace, history],
    );

    return (
        <div className={style.accountSelectorContainer}>
            <div ref={reference} className={style.dropDownContainer}>
                <DropDownButton
                    title={selectedWorkspace.name}
                    img={selectedWorkspace.isDefault ? <ClientOverviewIcon /> : null}
                    dropDownIsOpen={dropDownIsOpen}
                    toggleDropDown={handleToggleDropDown}
                    maxWidth="10rem"
                />

                <DropDownContainer
                    className={classnames(style.selectionDropDown, style.selectionAccount)}
                    isOpen={dropDownIsOpen}
                    anchor={"left"}
                    dropdownWidth="auto"
                >
                    <div className={style.listAccountContainer}>
                        <SearchBox
                            ref={ref}
                            className={style.searchTags}
                            placeholder={"Search Accounts"}
                            onChange={setSearchInput}
                            input={searchInput}
                        />

                        <div className={style.listContainer} style={{ height: "auto", maxHeight: "20rem" }}>
                            {workspacesFiltered
                                .sort((a, b) => {
                                    if (a.isDefault) return -1;
                                    if (b.isDefault) return 1;
                                    return a.name.localeCompare(b.name);
                                })
                                .map(({ id, name, isDefault, selected }) => (
                                    <LazyLoad key={id} offset={50}>
                                        <SimpleListItem
                                            wrap={true}
                                            isActive={selected}
                                            key={id}
                                            onClick={handleSelectWorkspace(id)}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
                                                {isDefault && <ClientOverviewIcon />}
                                                <span>{name}</span>
                                            </div>
                                        </SimpleListItem>
                                    </LazyLoad>
                                ))}
                        </div>
                    </div>
                </DropDownContainer>
            </div>
        </div>
    );
};

export default WorkspaceSelector;
