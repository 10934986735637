import React from "react";
import { CircularProgress } from "@material-ui/core";

export default ({ color = "#ffffff" }) => {
    return (
        <CircularProgress
            size={40}
            thickness={1}
            style={{
                height: "100%",
                left: "0",
                opacity: "0.25",
                position: "absolute",
                top: "4px",
                width: "100%",
                zIndex: "-99",
                color,
            }}
        />
    );
};
