import React from "react";
import Codeid from "../ConversionSettings/Codeid";
import { createSelector } from "reselect";
import { defaultState } from "./reducers";
import isEqual from "lodash/isEqual";

export const selectSettings = ({ kpi: { settings: { goals = defaultState } = {} } = {} } = {}) => goals;

/**
 * Analytics
 */
export const selectSettingsAnalyticsConversionGoals = createSelector(
    [selectSettings],
    ({ conversionTypes = [] }) => conversionTypes,
);

export const selectInitSettingsAnalyticsConversionGoals = createSelector(
    [selectSettings],
    ({ initConversionTypes = [] }) => initConversionTypes,
);

export const selectAnalyticsIntegration = createSelector(
    [selectSettings],
    ({ selectedAnalyticsIntegration = "" }) => selectedAnalyticsIntegration,
);

export const selectSettingsAnalyticsConversionGoalsByIntegration = createSelector(
    [selectSettingsAnalyticsConversionGoals, selectAnalyticsIntegration],
    (conversionTypes = [], selectedAnalyticsIntegration = "") =>
        conversionTypes.filter(({ analyticsViewId = "" }) => analyticsViewId === selectedAnalyticsIntegration),
);

export const selectExcludedAnalyticsConversionGoals = createSelector(
    [selectSettingsAnalyticsConversionGoals],
    (conversionTypes) =>
        conversionTypes
            .filter(({ id }) => id === "none")
            .reduce((cache, { analyticsViewId, checked }) => ({ ...cache, [analyticsViewId]: checked }), {}),
);

export const selectIntegrationsWithCurrentAndFutureGoals = createSelector(
    [selectSettingsAnalyticsConversionGoals],
    (conversionTypes) => {
        // all goals from a particular integration are FALSE and the none is not checked
        const groupByAnalyticsId = conversionTypes.reduce(
            (cache, { analyticsViewId, ...rest }) => ({
                ...cache,
                [analyticsViewId]: [
                    ...(cache[analyticsViewId] || []),
                    {
                        analyticsViewId,
                        ...rest,
                    },
                ],
            }),
            {},
        );

        return Object.keys(groupByAnalyticsId).reduce((cache, key) => {
            const goals = groupByAnalyticsId[key] || [];
            // No goals checked, including the "none" option
            const selectAllCurrentAndFuture = goals.every(({ checked = false }) => !checked);

            return {
                ...cache,
                [key]: selectAllCurrentAndFuture,
            };
        }, {});
    },
);

export const selectSettingsAnalyticsConversionGoalsPending = createSelector(
    [selectSettings],
    ({ conversionTypesPending }) => conversionTypesPending,
);

/**
 * Adwords
 */
export const selectAdwordsIntegration = createSelector(
    [selectSettings],
    ({ selectedAdwordsIntegration = "" }) => selectedAdwordsIntegration,
);

export const selectSettingsAdwordsConversionTypes = createSelector(
    [selectSettings],
    ({ adwordsBreakdown = {} }) => adwordsBreakdown,
);

export const selectExcludedAdwordsConversionGoals = createSelector([selectSettingsAdwordsConversionTypes], (types) =>
    Object.keys(types)
        .filter((key) => types[key]?.id === "none")
        .reduce(
            (cache, key) => ({
                ...cache,
                [types[key].integrationId]: Boolean(types[key].active),
            }),
            {},
        ),
);

export const selectIntegrationsWithCurrentAndFutureAdwordsConversions = createSelector(
    [selectSettingsAdwordsConversionTypes],
    (types) => {
        // all goals from a particular integration are FALSE and the none is not checked
        const groupByIntegrationId = Object.keys(types).reduce((cache, key) => {
            const { integrationId, ...rest } = types[key];
            return {
                ...cache,
                [integrationId]: [
                    ...(cache[integrationId] || []),
                    {
                        integrationId,
                        ...rest,
                    },
                ],
            };
        }, {});

        return Object.keys(groupByIntegrationId).reduce((cache, key) => {
            const goals = groupByIntegrationId[key] || [];
            // No goals checked, including the "none" option
            const selectAllCurrentAndFuture = goals.every(({ active = false }) => !active);

            return {
                ...cache,
                [key]: selectAllCurrentAndFuture,
            };
        }, {});
    },
);

export const selectSettingsAdwordsConversionTypesOptions = createSelector(
    [selectSettingsAdwordsConversionTypes],
    (types) =>
        Object.keys(types).map((type) => {
            const checked = Boolean(types[type].active);
            const name = types[type].name;

            return {
                checked,
                name,
                id: types[type].id,
                label: name,
                integrationId: types[type].integrationId,
            };
        }),
);

/**
 * AnalyticsV4
 */
export const selectAnalyticsV4Integration = createSelector(
    [selectSettings],
    ({ selectedAnalyticsV4Integration = "" }) => selectedAnalyticsV4Integration,
);

export const selectSettingsAnalyticsV4ConversionTypesLoading = createSelector(
    [selectSettings],
    ({ analyticsV4BreakdownLoading }) => analyticsV4BreakdownLoading,
);

export const selectSettingsAnalyticsV4ConversionTypes = createSelector(
    [selectSettings],
    ({ analyticsV4Breakdown = {} }) => analyticsV4Breakdown,
);

export const selectExcludedAnalyticsV4ConversionGoals = createSelector(
    [selectSettingsAnalyticsV4ConversionTypes],
    (types) => {
        return Object.keys(types)
            .filter((key) => types[key]?.id === "none")
            .reduce(
                (cache, key) => ({
                    ...cache,
                    [types[key].integrationId]: Boolean(types[key].active),
                }),
                {},
            );
    },
);

export const selectIntegrationsWithCurrentAndFutureAnalyticsV4Conversions = createSelector(
    [selectSettingsAnalyticsV4ConversionTypes],
    (types) => {
        // all goals from a particular integration are FALSE and the none is not checked
        const groupByIntegrationId = Object.keys(types).reduce((cache, key) => {
            const { integrationId, ...rest } = types[key];
            return {
                ...cache,
                [integrationId]: [
                    ...(cache[integrationId] || []),
                    {
                        integrationId,
                        ...rest,
                    },
                ],
            };
        }, {});

        return Object.keys(groupByIntegrationId).reduce((cache, key) => {
            const goals = groupByIntegrationId[key] || [];
            // No goals checked, including the "none" option
            const selectAllCurrentAndFuture = goals.every(({ active = false }) => !active);

            return {
                ...cache,
                [key]: selectAllCurrentAndFuture,
            };
        }, {});
    },
);

export const selectSettingsAnalyticsV4ConversionTypesOptions = createSelector(
    [selectSettingsAnalyticsV4ConversionTypes],
    (types) => {
        return Object.keys(types).map((type) => {
            const checked = Boolean(types[type].active);
            const name = types[type].name;

            return {
                checked,
                name,
                id: types[type].id,
                label: name,
                integrationId: types[type].integrationId,
            };
        });
    },
);

/**
 * Facebook
 */

export const selectSettingsFacebookConversionTypes = createSelector(
    [selectSettings],
    ({ facebookBreakdown = {} }) => facebookBreakdown,
);

export const selectInitSettingsFacebookConversionTypes = createSelector(
    [selectSettings],
    ({ initFacebookBreakdown = {} }) => initFacebookBreakdown,
);

export const selectAvailFacebookCustomConversions = createSelector(
    [selectInitSettingsFacebookConversionTypes],
    (initFacebookBreakdown = {}) =>
        Object.values(initFacebookBreakdown).reduce((cache, conversion) => {
            const { metric } = conversion || {};
            return conversion.isCustomConversion ? { ...cache, [metric]: conversion } : cache;
        }, {}),
);

export const selectSettingsFacebookConversionTypesLoading = createSelector(
    [selectSettings],
    ({ facebookBreakdownLoading }) => facebookBreakdownLoading,
);

export const selectSettingsFacebookAttribution = createSelector(
    [selectSettings],
    ({ facebookAttribution = {} }) => facebookAttribution,
);

export const selectSettingsPinterestAttribution = createSelector(
    [selectSettings],
    ({ pinterestAttribution = {} }) => pinterestAttribution,
);

export const selectSettingsFacebookConversionTypesOptions = createSelector(
    [selectSettingsFacebookConversionTypes],
    (types) =>
        Object.keys(types).map((type) => {
            const checked = Boolean(types[type].active);
            const common = Boolean(types[type].common);
            const isDefault = Boolean(types[type].isDefault);
            const name = types[type].name;

            return {
                checked,
                name,
                component: <Codeid id={type} />,
                dbid: type.split(".").join("-"),
                id: type,
                label: name,
                common,
                isDefault,
            };
        }),
);

export const selectSettingsFacebookDefaultConversionTypes = createSelector(
    [selectSettingsFacebookConversionTypes],
    (types) =>
        Object.keys(types).reduce((cache, key) => {
            const type = types[key] || {};
            const { isDefault = false } = type;

            return {
                ...cache,
                [key]: {
                    ...type,
                    checked: isDefault,
                },
            };
        }, {}),
);

/**
 * Linkedin
 */

export const selectSettingsLinkedinConversionTypes = createSelector(
    [selectSettings],
    ({ linkedinBreakdown }) => linkedinBreakdown,
);

export const selectInitSettingsLinkedinConversionTypes = createSelector(
    [selectSettings],
    ({ initLinkedinBreakdown }) => initLinkedinBreakdown,
);

export const selectSettingsLinkedinConversionTypesLoading = createSelector(
    [selectSettings],
    ({ linkedinBreakdownLoading }) => linkedinBreakdownLoading,
);

export const selectSettingsLinkedinDefaultConversionTypes = createSelector(
    [selectSettingsLinkedinConversionTypes],
    (types) => {
        return Object.keys(types).reduce((obj, type) => {
            return {
                ...obj,
                [type]: Boolean(types[type].active),
            };
        }, {});
    },
);

export const selectSettingsLinkedinConversionTypesOptions = createSelector(
    [selectSettingsLinkedinConversionTypes],
    (types) => {
        const isConversionsChecked = Boolean(types["externalWebsiteConversions"]?.active);

        const isSubConversionsChecked = Object.keys(types).some((type) => {
            return (
                (type === "externalWebsitePostClickConversions" || type === "externalWebsitePostViewConversions") &&
                types[type].active
            );
        });

        return Object.keys(types).map((type) => {
            const checked = Boolean(types[type].active);
            const name = types[type].name;
            const isDefault = Boolean(types[type].active);
            const common = Boolean(types[type].common);
            const isSubConversion =
                type === "externalWebsitePostClickConversions" || type === "externalWebsitePostViewConversions";
            const disabled =
                (isSubConversion && isConversionsChecked) ||
                (type === "externalWebsiteConversions" && isSubConversionsChecked);

            return {
                checked,
                name,
                id: type,
                label: name,
                disabled,
                autoChecked: isSubConversion && disabled,
                indent: isSubConversion,
                isDefault,
                common,
            };
        });
    },
);

/**
 * Shared
 */

export const selectSettingsAvailableChannels = createSelector(
    [selectSettings],
    ({ availableChannels = [] }) => availableChannels,
);

export const selectHasSettingsChanges = createSelector(
    [
        selectSettingsAnalyticsConversionGoals,
        selectInitSettingsAnalyticsConversionGoals,
        selectSettingsFacebookConversionTypes,
        selectInitSettingsFacebookConversionTypes,
        selectSettingsLinkedinConversionTypes,
        selectInitSettingsLinkedinConversionTypes,
    ],
    (currentAnalytics, initAnalytics, currentFacebook, initFacebook, currentLinkedin, initLinkedin) => {
        const analyticsEqual = isEqual(currentAnalytics, initAnalytics);
        const facebookEqual = isEqual(currentFacebook, initFacebook);
        const linkedinEqual = isEqual(currentLinkedin, initLinkedin);

        return !analyticsEqual || !facebookEqual || !linkedinEqual;
    },
);

export const selectConversionsTouched = createSelector(
    [selectSettings],
    ({ conversionsTouched = false }) => conversionsTouched,
);

/**
 * Conversion Groups
 */

export const selectCampaignGroups = createSelector([selectSettings], ({ campaignGroups = {} }) => campaignGroups);

export const makeSelectCampaignGroupById = (groupId) =>
    createSelector([selectSettings], ({ campaignGroups = {} }) => campaignGroups[groupId] || {});

export const selectCampaignGroupsLoading = createSelector(
    [selectSettings],
    ({ campaignGroupsLoading = false }) => campaignGroupsLoading,
);

export const selectChangeLogsPending = (state) => state.kpi.settings.goals?.changeLogsPending || false;

export const selectChangeLogs = (state) => state.kpi.settings.goals?.changeLogs || [];

export const selectChangeLogsById = (changeLogId) =>
    createSelector([selectChangeLogs], (changeLogs) => changeLogs.find(({ id }) => id === changeLogId) || {});
