import React from "react";
import { Table, TableBody } from "@material-ui/core";
import classNames from "classnames";
import { TooltipDark } from "components/Tooltip";
import { isEmpty } from "lodash";
import Row from "./Row";
import TableHead from "./TableHead";
import { getValidHeadCells, getRowSettings } from "./helpers";
import { useSelector } from "react-redux";
import { makeSelectTableSettings } from "_redux/sites/selectors";

const ALIGN_CLASSES = {
    center: "verticalAlignCenter",
    top: "verticalAlignTop",
};

export default ({
    withHeaders = true,
    rowsCount = 0,
    className,
    rowClass,
    hover = true,
    dividers = true,
    pagination = false,
    shortHeader = false,
    verticalAlign = "center",
    totalsRow,
    allowScroll = false,
    parentScrolled = false,
    activeMetricFilter,
    heatMapColumns = [],
    selected = [],
    showSelection = false,
    alwaysShowTotalRow = false,
    page,
    rowsPerPage,
    isScrolled,
    tableRef,
    paginatedRows,
    headCells,
    order,
    orderBy,
    handleRequestSort,
    handleSelectAllClick,
    headerOffset,
    totalActiveMetricDiff,
    handleClick,
    allowSelectCols,
    tableId,
    fixedColumns = [],
}) => {
    const hasTotalRow = !isEmpty(totalsRow) && (alwaysShowTotalRow || rowsCount > 1);
    const tableSettings = useSelector((state) => makeSelectTableSettings()(state));
    const columnsSetting = tableSettings?.[tableId] || [];
    const validHeadCells = getValidHeadCells({ headCells, columnsSetting, fixedColumns });

    return (
        <>
            <Table
                ref={tableRef}
                className={classNames("MorphioTable", {
                    [ALIGN_CLASSES[verticalAlign]]: verticalAlign,
                    allowScroll: allowScroll,
                    paginated: pagination,
                    emptyTable: isEmpty(paginatedRows),
                    [className]: className,
                })}
                style={{ position: "relative", overflow: "visible" }}
            >
                {withHeaders && (
                    <TableHead
                        allowSelectCols={allowSelectCols}
                        headCells={validHeadCells}
                        originHeadCells={headCells}
                        tableId={tableId}
                        columnsSetting={columnsSetting}
                        order={order === 1 ? "asc" : "desc"}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        dividers={dividers}
                        shortHeader={shortHeader}
                        isScrolled={isScrolled}
                        onSelectAllClick={handleSelectAllClick}
                        rowsCount={rowsCount}
                        numSelected={selected.length}
                        showSelection={showSelection}
                        headerOffset={headerOffset}
                        fixedColumns={fixedColumns}
                    />
                )}
                <TableBody>
                    {paginatedRows.map(({ id, ...row }, index) => {
                        const {
                            key,
                            heatMapSettings,
                            isItemSelected,
                            isLastRow,
                            activeMetricFilter: finalActiveMetricFilter = {},
                        } = getRowSettings({
                            row: { id, ...row, rowIndex: index },
                            paginatedRows,
                            activeMetricFilter,
                            totalActiveMetricDiff,
                            heatMapColumns,
                            selectedColumns: selected,
                        });

                        return (
                            <React.Suspense fallback={<div>Loading...</div>} key={key}>
                                <Row
                                    id={id}
                                    isItemSelected={isItemSelected}
                                    handleClick={handleClick}
                                    key={key}
                                    row={row}
                                    headCells={validHeadCells}
                                    hasHover={hover}
                                    rowClass={rowClass}
                                    verticalAlign={verticalAlign}
                                    isScrolled={isScrolled}
                                    parentScrolled={parentScrolled}
                                    orderBy={orderBy}
                                    order={order}
                                    isLastRow={isLastRow}
                                    index={index}
                                    activeMetricFilter={finalActiveMetricFilter}
                                    heatMapSettings={heatMapSettings}
                                    selected={selected}
                                    showSelection={showSelection}
                                />
                            </React.Suspense>
                        );
                    })}
                    {hasTotalRow && (
                        <React.Suspense fallback={<div>Loading...</div>}>
                            <Row
                                row={totalsRow}
                                headCells={validHeadCells}
                                hasHover={false}
                                rowClass={`${rowClass} totalsRow`}
                                verticalAlign={verticalAlign}
                                isScrolled={isScrolled}
                                index={0}
                            />
                        </React.Suspense>
                    )}
                </TableBody>
            </Table>
            <TooltipDark id="headTooltip" />
            <TooltipDark id="rowTooltip" proximity={2} />
        </>
    );
};
