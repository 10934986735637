const SEARCH_CONSOLE_TOTALS = "SEARCH_CONSOLE_TOTALS";
const SEARCH_CONSOLE_QUERIES = "SEARCH_CONSOLE_QUERIES";
const SEARCH_CONSOLE_PAGES = "SEARCH_CONSOLE_PAGES";
const CLEAR_SEARCH_CONSOLE_DATA_LOADING = "CLEAR_SEARCH_CONSOLE_DATA_LOADING";

export default {
    SEARCH_CONSOLE_TOTALS,
    SEARCH_CONSOLE_QUERIES,
    SEARCH_CONSOLE_PAGES,
    CLEAR_SEARCH_CONSOLE_DATA_LOADING,
};
