import * as yup from "yup";

export const columnsSchema = yup.object({
    selectedColumns: yup
        .array()
        .of(
            yup.object({
                id: yup.string().required("Id is required"),
                label: yup.string().required("Label is required"),
                checked: yup.boolean().required("Checked status is required"),
            }),
        )
        .min(1, "At least one column must be selected")
        .required("Selected columns are required"),
});
