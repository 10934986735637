import decimal from "decimal.js";
import _ from "lodash/fp";
import Numeral from "numeral";
import React from "react";
import compose from "lodash/fp/flowRight";
import curry from "lodash/fp/curry";
import getSymbolFromCurrency from "currency-symbol-map";
import { store } from "../_redux";
import { makeSelectCustomMetrics } from "_redux/users/selectors";
import { getPlatformMainKey } from "./utility";
import { bigQueryHelpers } from "./bigQuery";
import {
    FACEBOOK_CUSTOM_CONVERSION_COST_PER_PREFIX,
    FACEBOOK_CUSTOM_CONVERSION_PREFIX,
    FACEBOOK_CUSTOM_CONVERSION_VALUE_SUFFIX,
} from "./facebook/customConversions";

export const toDecimalJs = (number) => {
    return new decimal(Numeral(number).value() || 0);
};

export const getTrend = (cur = 0, prev = 0) => Math.sign(cur - prev);

export const isValidNumber = (number) => !isNaN(number) && isFinite(number);

export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

const evaluatedMetric = (newValue) => Math.round(parseFloat(newValue) * 100) / 100;

export const formatNumber = compose(numberWithCommas, evaluatedMetric);

export const parseNumberInputField = (value) => {
    if (!value) {
        return "";
    }

    let parsedValue = value.toString().replace(/\s/g, "");

    // dont allow any letters or symbols other than a period
    if (!parsedValue[parsedValue.length - 1].match(/^\d*\.?\d*$/)) {
        return;
    }

    // only allow 2 values after decimal
    if (parsedValue.indexOf(".") >= 0 && parsedValue.split(".")[1].length > 2) {
        return;
    }

    // no double periods
    if (parsedValue.indexOf(".") >= 0 && parsedValue.split(".").length > 2) {
        return;
    }

    //If period at end with no number returned
    if (parsedValue[parsedValue.length - 1] === ".") {
        return parsedValue;
    }

    //Format value to a pretty number
    const [number, decimal] = parsedValue.indexOf(".") >= 0 ? parsedValue.split(".") : [parsedValue, ""];
    const float = parseFloat(number.split(",").join(""));
    const prettyNumber = numberWithCommas(float);
    return decimal ? [prettyNumber, decimal].join(".") : prettyNumber;
};

const appendUnit = curry((unit, str) => {
    if (!str) return null;
    if (!unit) return str;
    if (["%", "s"].includes(unit)) return `${str}${unit}`;
    const numberPrefix = str.startsWith("-") ? "-" : "";
    return `${numberPrefix}${unit}${str.replace("-", "")}`;
});

export const currencyMetrics = [
    "platformcpl",
    "cpc",
    "itemrevenue",
    "shopifyrevenue",
    "shopifyavgordervalue",
    "avgtransactionvalue",
    "avgordervalue",
    "avg. order value",
    "budget",
    "cost",
    "dollar",
    "costadwords",
    "costbing",
    "costlinkedin",
    "costfacebook",
    "revenue",
    "spend",
    "cpa",
    "cpl",
    "cpa overall",
    "cpl overall",
    "cpa paid",
    "cplpaid",
    "cpapaid",
    "cpl paid",
    "cpl",
    "cpm",
    "cplpaid",
    "revenuegoalcompletions",
    "revenuetransactions",
    "estimated revenue",
    "currency",
    "fb_pixel_purchase_value",
    "fb_pixel_add_to_cart_value",
    "fb_pixel_initiate_checkout_value",
    "onsite_conversion-purchase_value",
    "conversionvalue",
    "conversionsvaluebyconversiondate",
    "ltv",
    "totalrevenue",
    "costperpageview",
    "costperpageviewpage",
    "costperproductview",
    "costperproductviewpage",
    "costperaddtocart",
    "costperaddtocartpage",
    "costpercompletepayment",
    "costpercompletepaymentpage",
    "completepaymentvalue",
    "completepaymentvaluepage",
    "costpercheckout",
    "costpercheckoutpage",
    "grosssales",
    "discounts",
    "shipping",
    "taxes",
    "returns",
    "netsales",
    "totalsales",
    "newcustomergmv",
    "newcustomeraov",
    "returningcustomergmv",
    "returningcustomeraov",
    "gmv",
    "aov",
    "averagesaleprice",
    "shopifyaov",
    "woocommerceaov",
    "magentoaov",
    "totalaov",
    "flowshopifyaov",
    "flowwoocommerceaov",
    "flowmagentoaov",
    "flowtotalaov",
    "cpv",
    "purchasevalue",
    "cpp",
    "websitecpp",
    "metacpp",
    "purchaserevenue",
    "grossitemrevenue",
    "itemdiscountamount",
    "itemrefundamount",
    "cpleadgrouped",
    "subscriptionvalue",
    "costpersubscription",
    "costper_fb_pixel_lead",
    "cpe",
    "conversionrevenue",
    "revenuefee",
];

export const percentMetrics = [
    "outboundctr",
    "ctr",
    "ctrall",
    "roi",
    "percent",
    "percentdecimal",
    "goal conversion rate all",
    "bouncerate",
    "bounce rate",
    "conversion rate",
    "compare",
    "conversionrate",
    "engagementrate",
    "engagement rate",
    "goalcompletionrate",
    "transactionrate",
    "change",
    "conversionratebyconversiondate",
    "impressionshare",
    "displayimpressionshare",
    "searchlostisrank",
    "searchlostisbudget",
    "displaylostisrank",
    "displaylostisbudget",
    "phonethroughrate",
    "viewrate",
    "fb_pixel_add_to_cart_percent",
    "fb_pixel_purchase_percent",
    "videoviewrate",
    "openrate",
    "ctor",
    "flowopenrate",
    "flowctor",
    "flowctr",
    "interactionrate",
    "sessionconversionrate",
    "organicengagementrate",
    "fanagerangespercent",
    "fangenderspercent",
    "pageimpressionsbyageuniquepercent",
    "pageimpressionsbygenderuniquepercent",
    "videocompletionrate",
    "clickrate",
];

export const timeMetrics = [
    "avg time on site",
    "avgtimeonsite",
    "avgpageloadtime",
    "seconds",
    "averagesessionduration",
    "avgengagementtime",
    "avgengagementtimepersession",
];

const getMetricKey = (metric) => {
    if (!metric) {
        return "";
    }

    if (!metric.includes("-")) {
        return metric;
    }

    const [_, ...metricKeys] = metric.split("-");
    return metricKeys.join("-");
};

const isCurrencyMetric = (metric) => {
    if (!metric) {
        return false;
    }

    const metricKey = getMetricKey(metric);

    return (
        currencyMetrics.includes(metricKey.toLowerCase()) ||
        metricKey.startsWith("costPer_") ||
        metricKey.endsWith("_value")
    );
};

const getCurrency = ({ accountId, metric }) => {
    if (accountId) {
        const {
            views: { available: { [accountId]: { currency: defaultCurrency = "USD" } = {} } = {} } = {},
        } = store.getState();
        return defaultCurrency;
    }

    const { sites: { selectedSite: { currency = "USD", ...accountInfo } = {} } = {} } = store.getState();

    if (
        metric.includes("-") &&
        !metric.includes("total-") &&
        !metric.includes("custom-") &&
        !metric.startsWith("campaignGroup")
    ) {
        // If metric exists
        const platform = metric.split("-")[0];
        const { integrations: { [getPlatformMainKey(platform)]: integrations = {} } = {} } = accountInfo;
        const [{ currency: platformCurrency = "USD" } = {}] = Object.values(integrations); // any of the integrations
        return platformCurrency;
    }

    // default
    return currency || "USD";
};

const getMetricFormat = (metric) => {
    if (
        metric.includes(FACEBOOK_CUSTOM_CONVERSION_PREFIX) &&
        (metric.includes(FACEBOOK_CUSTOM_CONVERSION_COST_PER_PREFIX) ||
            metric.includes(FACEBOOK_CUSTOM_CONVERSION_VALUE_SUFFIX))
    ) {
        return metric;
    }

    if (metric.includes("custom-") && !["custom-currency", "custom-percent"].includes(metric)) {
        const customMetrics = makeSelectCustomMetrics()(store.getState());
        const { [metric]: { format = "number" } = {} } = customMetrics;
        return format;
    }

    return metric;
};

export const getUnit = (metric, accountId) => {
    if (metric === "dollar") {
        return "$";
    }

    const validMetric = getMetricFormat(metric);

    if (!validMetric) {
        return null;
    }

    if (isCurrencyMetric(validMetric)) {
        const currency = getCurrency({ accountId, metric: validMetric });
        return getSymbolFromCurrency(currency);
    }

    const metricKey = getMetricKey(validMetric);

    if (percentMetrics.includes(metricKey.toLowerCase())) {
        return "%";
    }

    if (timeMetrics.includes(metricKey.toLowerCase())) {
        return "s";
    }

    return null;
};

export const createUnit = (unit, str) => appendUnit(getUnit(unit), str);

export const getMetric = (metric) => {
    const [platform, metricKey] = metric.split(/-(.*)/);
    return { platform, metric: metricKey };
};

const getDecimals = (metric) => {
    if (metric.includes("custom-")) {
        return 2;
    }
    if (metric.includes("-")) {
        const { metric: metricKey } = getMetric(metric);
        metric = metricKey;
    }
    if (
        [
            "platformconversions",
            "shopifytransactions",
            "roi",
            "placements",
            "users",
            "newusers",
            "number",
            "percent",
            "clicks",
            "transactions",
            "sessions",
            "avgposition",
            "goalcompletions",
            "impressions",
            "anomalies",
            "dollar",
            "purchases",
            "offsite_conversion-fb_pixel_purchase",
            "onsite_conversion-purchase",
            "fb_pixel_purchase",
            "fb_pixel_initiate_checkout",
            "fb_pixel_add_to_cart",
            "reach",
            "callclicks",
            "websiteclicks",
            "businessbookings",
            "businessfoodorders",
            "totalreviewcount",
            "totalreviewcountlifetime",
            "searchrank",
            "impressionshare",
            "displayimpressionshare",
            "phonecalls",
            "phoneimpressions",
            "activeusers",
            "addtocarts",
            "pageviews",
            "totalpageviewpage",
            "productviews",
            "productviewspage",
            "addtocart",
            "addtocartpage",
            "completepayments",
            "completepaymentpage",
            "checkouts",
            "subscribes",
            "subscribepage",
            "clicked",
            "opened",
            "sent",
            "bounced",
            "unsubscribed",
            "subscribers",
            "uniqueclicks",
            "uniqueopens",
            "outboundclicks",
            "shopifyorders",
            "woocommerceorders",
            "magentoorders",
            "totalorders",
            "flowclicked",
            "flowopened",
            "flowbounced",
            "flowunsubscribed",
            "flowuniqueclicks",
            "flowuniqueopens",
            "flowdelivered",
            "flowshopifyorders",
            "flowwoocommerceorders",
            "flowmagentoorders",
            "flowtotalorders",
            "listsubscribed",
            "listunsubscribed",
            "orders",
            "items",
            "newcustomerorders",
            "returningcustomerorders",
            "interactions",
            "engagements",
            "videoviews",
            "landingpageviews",
            "contentviews",
            "activeusers",
            "totalusers",
            "returningusers",
            "engagedSessions",
            "bounces",
            "eventcount",
            "averagesessionduration",
            "omniaddtocart",
            "organicimpressions",
            "organicengagements",
            "organicengagements",
            "organiclinkclicks",
            "pagedailyfollows",
            "netfollowergrowth",
            "pagefans",
            "pagefanremoves",
            "paidimpressions",
            "pageimpressions",
            "fullvideoviews",
            "totalpublishedposts",
            "fanageranges",
            "pageimpressionsbyageunique",
            "fangenders",
            "pageimpressionsbygenderunique",
            "pagefanscountry",
            "pageimpressionsbycountryunique",
            "pageimpressionsbycityunique",
            "videofullviews",
            "clicksall",
            "pageengagements",
            "totalengagements",
            "otherclicks",
            "linkclicks",
            "shares",
            "comments",
            "reactions",
            "itemsaddedtocart",
            "itemscheckedout",
            "itemspurchased",
            "itemsviewed",
            "subscriptions",
            "secondaryconversions",
            "videostarts",
            "videocompletions",
            "uniqueimpressions",
            "reach",
            "recipients",
            "opens",
            "clicks",
            "bounces",
            "likes",
            "saves",
            "replies",
            "followers",
            "unfollowers",
            "city",
            "gender",
            "country",
            "age",
        ].includes(metric.toLowerCase().replace(" ", ""))
    ) {
        return 0;
    } else {
        return 2;
    }
};

const prettyNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const formatPrettyNumber = ({ value, unit, decimals }) => {
    if (["s"].includes(unit)) {
        return convertToMinutesAndSeconds(Number(value).toFixed(decimals));
    }

    if (value < 10000000) {
        return appendUnit(unit, prettyNumberWithCommas(Number(value).toFixed(decimals)));
    }

    const suffixes = ["k", "M", "B"];
    const exp = Math.floor(Math.log(value) / Math.log(1000));
    return appendUnit(unit, prettyNumberWithCommas(Number(value / Math.pow(1000, exp)).toFixed(2)) + suffixes[exp - 1]);
};

export const toPrettyNumber = (input, metric, accountId, defaultValue = null, forcedUnit) => {
    if (!metric) {
        return appendUnit(null, prettyNumberWithCommas(Number(input).toFixed(2)));
    }

    if (isNaN(input) || !isFinite(input)) {
        return defaultValue;
    }

    if (bigQueryHelpers.isBigQueryMetric(metric)) {
        const { unit, decimals } = bigQueryHelpers.getBigQueryMetricFormat(metric);
        return formatPrettyNumber({ value: input, unit, decimals });
    }

    const unit =
        forcedUnit && isCurrencyMetric(metric) ? getSymbolFromCurrency(forcedUnit) : getUnit(metric, accountId);
    const decimals = input === 0 || input >= 1000 ? 0 : getDecimals(metric);

    return formatPrettyNumber({ value: input, unit, decimals });
};

export const getAndAppendUnit = (unit, value) => appendUnit(getUnit(unit))(value);

export const divide = (a, b, r = Infinity) => {
    if (b === 0) return r;
    return parseFloat(a) / parseFloat(b);
};

export const times = (a, b) => {
    return toDecimalJs(a)
        .times(b)
        .toNumber();
};

export const toTwoDecimalPlaces = (number) =>
    toDecimalJs(number)
        .toDecimalPlaces(2)
        .toNumber();

export const round = (number) => Math.round(number);

export const calculateRate = (a, b) =>
    toTwoDecimalPlaces(
        toDecimalJs(a)
            .dividedBy(b)
            .times(100)
            .toNumber(),
    );

export const calculateGrowth = (current, previous) => {
    if (previous === 0 && current === 0) return 0;
    if (previous === 0) return 100;
    return 100 * divide(current - previous, previous);
};

export const calcConversionRate = ({ conversions, clicks }) => calculateRate(conversions, clicks);

export const calcCostPerConversion = ({ cost, conversions }) => toTwoDecimalPlaces(divide(cost, conversions));

export const calcCostPerSession = ({ cost, sessions }) => toTwoDecimalPlaces(divide(cost, sessions));

export const calcClickThrough = ({ clicks, impressions }) => calculateRate(clicks, impressions);

export const calcCostPerClick = ({ cost, clicks }) => divide(cost, clicks);

export const growthNumberByPercent = ({ prevYear, growthPercent }) => {
    return toDecimalJs(prevYear)
        .times(toDecimalJs(growthPercent).plus(1))
        .toNumber();
};

export const calculateRoi = ({ revenue, spend, fees }) => {
    if (!revenue || !spend || !fees) return 0;
    const sum = toDecimalJs(spend).plus(fees);
    const a = toDecimalJs(revenue).minus(sum);
    return toTwoDecimalPlaces(a.dividedBy(sum));
};

export const calculateRoas = ({ revenue, spend }) => {
    if (!revenue || !spend) return 0;
    return toTwoDecimalPlaces(toDecimalJs(revenue).dividedBy(spend));
};

export const monthToDate = (monthlyGoal, numberOfDays, numberOfDaysInto) => {
    const a = toDecimalJs(monthlyGoal).dividedBy(numberOfDays);
    const b = a.times(numberOfDaysInto);
    const c = b.dividedBy(monthlyGoal);

    return toTwoDecimalPlaces(c.times(100));
};

export const growthPercentTotal = ({ currentYearSum, prevYearSum }) => {
    const difference = toDecimalJs(currentYearSum).minus(prevYearSum);
    const percentInterger = difference.dividedBy(prevYearSum);

    return toTwoDecimalPlaces(percentInterger.times(100).toNumber());
};

export const sum = (id) =>
    _.reduce((prev, next) => {
        return (prev += parseFloat(next[id]) || 0);
    }, 0);

const formatType = (type) => (number) => {
    const { sites: { selectedSite: { currency = "USD" } = {} } = {} } = store.getState();

    if (isNaN(number) || !isFinite(number)) {
        return "N/A";
    }
    const formattedNumber = Numeral(number).format("0,0.[00]");
    switch (type) {
        case "percent":
            return formattedNumber + "%";
        case "dollar":
            return getSymbolFromCurrency(currency) + formattedNumber;
        case "second":
            return formattedNumber + "s";
        default:
            return formattedNumber;
    }
};

const formatComponent = (type, className) => (number) => {
    if (isNaN(number) || !isFinite(number)) {
        return "N/A";
    }

    const formattedNumber = Numeral(number).format("0,0.[00]");

    return {
        percent: (
            <span className={className}>
                {formattedNumber}
                <span>%</span>
            </span>
        ),
        dollar: (
            <span className={className}>
                <span>$</span>
                {formattedNumber}
            </span>
        ),
        second: (
            <span className={className}>
                {formattedNumber}
                <span>s</span>
            </span>
        ),
        default: <span className={className}>{formattedNumber}</span>,
    }[type || "default"];
};

const checkComponentType = (type, className) => _.flow(toTwoDecimalPlaces, formatComponent(type, className));

export const componentTypes = (type, className) =>
    ({
        number: checkComponentType("default", className),
        percent: checkComponentType("percent", className),
        dollar: checkComponentType("dollar", className),
        second: checkComponentType("second", className),
    }[type || "number"]);

const checkTypes = (type) => _.flow(toTwoDecimalPlaces, formatType(type));

export const number = checkTypes("");
export const percent = checkTypes("percent");
export const dollar = checkTypes("dollar");
export const second = checkTypes("second");

export const types = {
    number,
    percent,
    dollar,
    second,
};

export const getRelatedMetrics = (metric) => {
    switch (metric) {
        case "cpl":
            return ["cost", "conversions"];
        case "cpc":
            return ["cost", "clicks"];
        case "conversionRate":
            return ["conversions", "clicks", 100];
        case "cpm":
            return ["cost", "impressions", 1000];
        case "bounceRate":
            return ["bounces", "sessions", 100];
        case "transactionRate":
            return ["transactions", "sessions", 100];
        case "goalCompletionRate":
            return ["goalCompletions", "sessions", 100];
        default:
            return [metric];
    }
};

const convertToMinutesAndSeconds = (seconds) => {
    const secondsFormatted = parseInt(seconds);

    if (!secondsFormatted || secondsFormatted < 0) {
        return 0;
    }

    const minutes = Math.floor(secondsFormatted / 60);
    const minutesPharse = minutes ? `${minutes}m` : "";

    const remainingSeconds = secondsFormatted % 60;
    const secondsPharse = remainingSeconds ? `${remainingSeconds}s` : "";

    const spaceBetweenMinAndSec = minutes && remainingSeconds ? " " : "";

    return `${minutesPharse}${spaceBetweenMinAndSec}${secondsPharse}`;
};
