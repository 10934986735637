import React, { useState } from "react";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import HelpIcon from "assets/images/icons/JSX/CircleHelpIcon";
import ArrowSort from "assets/images/icons/JSX/Icon-Arrow-Sort";
import classNames from "classnames";
import Checkbox from "components/Checkbox";
import style from "./style.module.scss";
import ColumnSettingsDialog from "./ColumnSettingsDialog";
import OptionsIcon from "assets/images/Dashboard/JSX/HorizontalMore";
import { getTableCellWidth } from "./helpers";

export default ({
    headCells,
    order,
    orderBy,
    onRequestSort,
    dividers,
    isScrolled = false,
    onSelectAllClick,
    numSelected,
    rowsCount,
    showSelection,
    headerOffset,
    allowSelectCols,
    tableId,
    originHeadCells,
    columnsSetting,
    fixedColumns,
}) => {
    const [isOpenDialog, setIsOpenDialog] = useState(false);

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const handleOpenDialog = () => {
        setIsOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setIsOpenDialog(false);
    };

    return (
        <TableHead>
            <TableRow
                style={{
                    transform: `translateY(${headerOffset}px)`,
                    position: "sticky",
                    backgroundColor: "white", // Ensure the background is set to avoid transparency issues
                    zIndex: 3,
                    boxShadow: headerOffset > 0 ? "0px 4px 4px rgba(0, 0, 0, 0.05)" : "none",
                }}
            >
                {showSelection && (
                    <TableCell className={style.tableCell}>
                        <Checkbox
                            className={style.checkbox}
                            hideLabel
                            input={{
                                checked: rowsCount > 0 && numSelected === rowsCount,
                                onChange: onSelectAllClick,
                                inputprops: { "aria-label": "select all desserts" },
                            }}
                        />
                    </TableCell>
                )}

                {Object.keys(headCells).map((key) => {
                    const { headPadding = "", className, align = "left", ...headCell } = headCells?.[key] || {};

                    const setPadding =
                        headPadding ||
                        (align === "right" ? "1rem 0.875rem 1rem 0.625rem" : "1rem 0.625rem 1rem 0.875rem");

                    return (
                        <TableCell
                            width={getTableCellWidth({ width: headCell.width, allowSelectCols })}
                            padding="none"
                            key={headCell.id}
                            align={align}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className={classNames("", {
                                emptyCell: !headCell.label,
                                noDividers: !dividers,
                                freezeCell: headCell.freeze,
                                isScrolled: headCell.freeze && isScrolled,
                                [className]: className,
                            })}
                        >
                            {headCell.sortable ? (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                    style={{
                                        padding: setPadding,
                                        minWidth: headCell.minWidth || 0,
                                    }}
                                >
                                    {headCell.label}
                                    {headCell.info && (
                                        <div
                                            className={style.headTooltip}
                                            data-html={true}
                                            data-tip={headCell.info}
                                            data-for="headTooltip"
                                        >
                                            <HelpIcon />
                                        </div>
                                    )}
                                    <div className={style.headCellActions}>
                                        <ArrowSort className="arrowSort" />
                                        {allowSelectCols && (
                                            <div
                                                className="optionsIcon"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOpenDialog();
                                                }}
                                            >
                                                <OptionsIcon />
                                            </div>
                                        )}
                                    </div>
                                </TableSortLabel>
                            ) : (
                                <span
                                    style={{
                                        padding: setPadding || "1.25rem 1rem 1.125rem",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: align === "left" ? "row" : "row-reverse",
                                        minWidth: headCell.minWidth || 0,
                                        minHeight: "3.5rem",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {headCell.label}
                                    {headCell.info && (
                                        <div
                                            className={style.headTooltip}
                                            data-html={true}
                                            data-tip={headCell.info}
                                            data-for="headTooltip"
                                        >
                                            <HelpIcon />
                                        </div>
                                    )}
                                    {allowSelectCols && (
                                        <div
                                            className={classNames("optionsIcon", style.tableOptionsIcon)}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenDialog();
                                            }}
                                        >
                                            <OptionsIcon />
                                        </div>
                                    )}
                                </span>
                            )}
                        </TableCell>
                    );
                })}
                {allowSelectCols && (
                    <ColumnSettingsDialog
                        isOpen={isOpenDialog}
                        onClose={handleCloseDialog}
                        headCells={originHeadCells}
                        tableId={tableId}
                        columnsSetting={columnsSetting}
                        fixedColumns={fixedColumns}
                    />
                )}
            </TableRow>
        </TableHead>
    );
};
