import { isEmpty } from "lodash";
import { capitalize } from "lib/utility";

const convertColumn = (item, headCells) => ({
    id: item.id,
    label: getColumnLabel(headCells, item.id),
    checked: true,
});

const getColumnLabel = (headCells, id) => {
    return headCells[id]?.label || capitalize(id);
};

const sortArrayByOrder = (array) => {
    return array.sort((a, b) => a.order - b.order);
};

export const getColumnOptions = ({ selectedColumns = [], headCells = {} }) => {
    const selectedIds = new Set(selectedColumns.map((item) => item.id));

    return Object.values(headCells).map(({ id, label }) => ({
        id,
        label: label || capitalize(id),
        checked: selectedIds.has(id),
    }));
};

export const getInitialColumns = ({ columnsSetting = [], headCells = {} }) => {
    if (!isEmpty(columnsSetting)) {
        return sortArrayByOrder(columnsSetting).map((item) => convertColumn(item, headCells));
    }

    return Object.values(headCells).map((item) => convertColumn(item, headCells));
};

export const filterHeadCells = ({ headCells = {}, fixedColumns = [] }) => {
    const clonedHeadCells = { ...headCells };
    fixedColumns.forEach(({ id }) => {
        delete clonedHeadCells[id];
    });

    return clonedHeadCells;
};
