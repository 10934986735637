import { metricFriendlyName } from "lib/utility";

export const MAIN_PLATFORM = "facebook";
export const FACEBOOK_METRIC_PREFIX = `${MAIN_PLATFORM}-`;
export const FACEBOOK_CUSTOM_CONVERSION_PREFIX = "offsite_conversion-custom-";
export const FACEBOOK_CUSTOM_CONVERSION_COST_PER_PREFIX = "costPer_";
export const FACEBOOK_CUSTOM_CONVERSION_VALUE_SUFFIX = "_value";

const isCustomConversionCostPerMetric = ({ platform, metricKey }) =>
    platform === MAIN_PLATFORM &&
    metricKey.includes(FACEBOOK_CUSTOM_CONVERSION_PREFIX) &&
    metricKey.startsWith(FACEBOOK_CUSTOM_CONVERSION_COST_PER_PREFIX);

const isCustomConversionValueMetric = ({ platform, metricKey }) =>
    platform === MAIN_PLATFORM &&
    metricKey.includes(FACEBOOK_CUSTOM_CONVERSION_PREFIX) &&
    metricKey.endsWith(FACEBOOK_CUSTOM_CONVERSION_VALUE_SUFFIX);

export const isCustomConversionMetric = ({ platform, metricKey }) =>
    isCustomConversionCostPerMetric({ platform, metricKey }) || isCustomConversionValueMetric({ platform, metricKey });

export const getCustomConversionMetricName = ({ platform, metricKey }) => {
    if (isCustomConversionCostPerMetric({ platform, metricKey })) {
        const parentConversionKey = metricKey.replace(FACEBOOK_CUSTOM_CONVERSION_COST_PER_PREFIX, "");
        return `Cost Per ${metricFriendlyName(`${platform}-${parentConversionKey}`, platform)}`;
    }

    if (isCustomConversionValueMetric({ platform, metricKey })) {
        const valueKeyIndex = metricKey.lastIndexOf(FACEBOOK_CUSTOM_CONVERSION_VALUE_SUFFIX);
        const parentConversionKey = valueKeyIndex === -1 ? "" : metricKey.slice(0, valueKeyIndex);

        return parentConversionKey
            ? ` ${metricFriendlyName(`${platform}-${parentConversionKey}`, platform)} Value`
            : metricKey;
    }

    return metricKey;
};
