import React, { useState } from "react";
import Button from "components/Button";
import style from "./style.module.scss";
import classNames from "classnames";
import ReactDOM from "react-dom";
import { ReactComponent as HawkeLogoWhite } from "assets/images/Logo/LogoWhite.svg";
import Dialog from "components/Dialog";
import { TooltipDark } from "components/Tooltip";
import { ReactComponent as HawkeLogo } from "assets/images/Logo/Logo.svg";
import { CSSTransition } from "react-transition-group";

const CloseDialog = ({ children, toggleDialog, isOpen, close, title }) => {
    return (
        <Dialog
            title={title}
            actions={[
                <Button key="cancel-button" label="Cancel" tertiary onClick={() => toggleDialog(false)}>
                    Cancel
                </Button>,
                <Button
                    key="exit-setup-button"
                    onClick={() => {
                        toggleDialog(false);
                        close();
                    }}
                >
                    Exit Setup
                </Button>,
            ]}
            open={isOpen}
        >
            {children}
        </Dialog>
    );
};

export default ({
    children,
    sidebar: Sidebar,
    isOpen,
    close,
    hideCloseButton,
    closeAlert: CloseAlert = null,
    className,
    closeTip = "Exit",
    hasLogo = false,
    sideBarIn,
    closeButton: CloseButton,
    spaceAround = false,
}) => {
    const [dialogOpen, toggleDialog] = useState(false);

    return ReactDOM.createPortal(
        <>
            <div
                className={classNames(style.modal, {
                    [style.open]: isOpen,
                    [style.spaceAround]: spaceAround,
                    [className]: className,
                })}
            >
                {typeof close === "function" &&
                    !hideCloseButton &&
                    (typeof CloseButton === "function" ? (
                        <CloseButton />
                    ) : (
                        <>
                            <div className={style.closeWrapper} data-tip={closeTip} data-for="modalClose">
                                <div
                                    className={`Close-Large ${style.close}`}
                                    onClick={() => {
                                        typeof CloseAlert === "function" ? toggleDialog(true) : close();
                                    }}
                                >
                                    <div>
                                        <span>close</span>
                                    </div>
                                </div>
                            </div>
                            <TooltipDark id="modalClose" position="left" delay />
                        </>
                    ))}
                <CSSTransition
                    in={sideBarIn}
                    classNames={{
                        enter: style.sideBarEnter,
                        enterActive: style.sideBarEnterActive,
                        enterDone: style.sideBarEnterDone,
                    }}
                    timeout={500}
                >
                    <div className={`${style.sidebar} ${typeof Sidebar === "function" && style.show}`}>
                        {typeof Sidebar === "function" && (
                            <>
                                <HawkeLogoWhite />
                                <Sidebar />
                            </>
                        )}
                    </div>
                </CSSTransition>
                <div className={`${style.modalWrapper}`}>
                    {hasLogo && <HawkeLogo />}
                    <div className={`${style.modalContent}`}>{children}</div>
                </div>
            </div>
            {typeof CloseAlert === "function" && !hideCloseButton && (
                <CloseDialog toggleDialog={toggleDialog} isOpen={dialogOpen} close={close}>
                    <CloseAlert />
                </CloseDialog>
            )}
        </>,
        document.body,
    );
};
