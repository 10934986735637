import React from "react";
import { Table, TableFooter, TablePagination, TableRow } from "@material-ui/core";
import classNames from "classnames";

export default ({
    rowsCount = 0,
    allowScroll = false,
    rowsPerPageOptions = [10, 25, 50, 100],
    page,
    onChangePage,
    rowsPerPage,
    onChangeRowsPerPage,
}) => {
    const handleChangePage = (event, newPage) => {
        onChangePage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        onChangeRowsPerPage(parseInt(event.target.value, 10));
        onChangePage(0);
    };

    return (
        <Table className={classNames("PaginationTable", { allowScroll })}>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        count={rowsCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{
                            inputProps: {
                                "aria-label": "rows per page",
                            },
                        }}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    );
};
