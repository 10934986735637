import React, { memo } from "react";
import { IconButton } from "@material-ui/core";
import { ReactComponent as DragIcon } from "assets/images/icons/Icon-Drag-Long.svg";
import CloseIcon from "assets/images/icons/JSX/Icon-Close-Small";
import classNames from "classnames";
import style from "./style.module.scss";

const ColumnItem = memo(
    ({
        column = {},
        isDragOverlay = false,
        isDragging = false,
        attributes = {},
        listeners = {},
        onDelete = () => {},
        ...props
    }) => {
        const wrapperClasses = classNames(style.selectedColumn, {
            [style.isDragOverlay]: isDragOverlay,
            [style.isDragging]: isDragging,
        });

        return (
            <div className={wrapperClasses} {...attributes} {...listeners}>
                <div className={style.dragHandle}>
                    <DragIcon />
                </div>
                <span className={style.columnName}>{column.label}</span>
                <IconButton
                    size="small"
                    className={style.closeIcon}
                    onPointerDown={(e) => {
                        e.stopPropagation();
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        onDelete();
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        );
    },
);

export default ColumnItem;
