import React from "react";

export default ({ children, handleScroll, scrollRef, currentRowsCount = 0, isPaginated = false }) => {
    return (
        <>
            <div
                ref={scrollRef}
                className={`TableScrollWrapper ${currentRowsCount === 0 && "emptyWrapper"}`}
                onScroll={handleScroll}
            >
                {children}
            </div>
            {currentRowsCount > 0 && <div className={`ScrollSpacer ${isPaginated && "isPaginated"}`}></div>}
        </>
    );
};
