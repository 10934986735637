import React, { useState, useEffect, useRef } from "react";
import Button from "components/Button";
import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectUserActiveFeatures, makeSelectUserName, makeSelectUserPicture } from "_redux/users/selectors";
import ReactMarkdown from "react-markdown";
import createSocketConnection from "./socket";
import ThreeDots from "components/Loading/ThreeDots";
import SendIcon from "assets/images/Menu/JSX/Email";
import { TextareaAutosize } from "@material-ui/core";
import { getSamplePrompts } from "./helper";
import remarkGfm from "remark-gfm";
import { mixpanel } from "components/Mixpanel";
import { makeSelectAccountId, makeSelectChatbotData, makeSelectChatbotIsOpen } from "_redux/sites/selectors";
import { updateChatbot } from "_redux/sites";
import IconChatGPT from "assets/images/icons/JSX/Icon-ChatGPT";
import IconChatGPTSVG from "assets/images/icons/Icon-ChatGPT.svg";
import Avatar from "components/Avatar";
import { history } from "_redux";
import CloseIcon from "assets/images/icons/JSX/Icon-Close-Small";
import isEmpty from "lodash/isEmpty";

export default ({} = {}) => {
    const [socketIsReady, setSocketIsReady] = useState(false);
    const [chatInput, setChatInput] = useState("");
    const [conversation, setConversation] = useState([]);
    const [currentRequestId, setCurrentRequestId] = useState(null);
    const currentRequestIdRef = useRef(currentRequestId);
    const [socket, setSocket] = useState(null);
    const isOpen = useSelector((state) => makeSelectChatbotIsOpen()(state));
    const dataToAttach = useSelector((state) => makeSelectChatbotData()(state));
    const userActiveFeatures = useSelector((state) => makeSelectUserActiveFeatures()(state));
    const userPicture = useSelector((state) => makeSelectUserPicture()(state));
    const model = userActiveFeatures["gpt4"] ? "gpt4" : "gpt3.5";
    const accountId = useSelector((state) => makeSelectAccountId()(state));
    const userNickName = useSelector((state) => makeSelectUserName()(state));
    const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const [visible, setVisible] = useState(false);
    const middleRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        currentRequestIdRef.current = currentRequestId;
    }, [currentRequestId]);

    // only update it if accountId changes
    const prevAccountIdRef = useRef(null); // Store previous accountId

    useEffect(() => {
        // Only refresh if accountId has changed
        if (prevAccountIdRef.current === accountId) return;

        prevAccountIdRef.current = accountId; // Update ref with the new accountId

        const newSocket = createSocketConnection();

        newSocket.on("connect", () => {
            console.log("Socket connected successfully.");
            setSocketIsReady(true); // Mark socket as ready
        });

        newSocket.on("response", (response) => {
            const responseObj = JSON.parse(response);
            setConversation((prev) =>
                prev.map((msg) =>
                    msg.sender === "assistant" && msg.requestId === responseObj.requestId
                        ? { ...msg, content: responseObj.body }
                        : msg,
                ),
            );
        });

        newSocket.on("attachment", (data) => {
            const dataUrl = `data:${data.contentType};base64,${data.data}`;
            setConversation((prev) =>
                prev.map((msg) =>
                    msg.sender === "assistant" && msg.requestId === data.requestId
                        ? {
                              ...msg,
                              attachment: {
                                  dataUrl,
                                  filename: data.filename,
                                  contentType: data.contentType,
                              },
                          }
                        : msg,
                ),
            );
        });

        setSocket(newSocket);

        return () => newSocket.disconnect();
    }, [accountId]);

    const handleSubmit = (query, data) => {
        const newRequestId = Date.now();
        setCurrentRequestId(newRequestId);

        const userMessage = {
            id: newRequestId,
            sender: "user",
            content: data ? `🗂️ Data from ${data.title} has been attached. ${query}` : query,
            requestId: newRequestId,
        };

        const assistantMessage = {
            id: newRequestId,
            sender: "assistant",
            content: "",
            requestId: newRequestId,
        };

        setConversation([...conversation, userMessage, assistantMessage]);
        setChatInput("");

        if (socket && socket.connected) {
            mixpanel.track("ChatGPT - Ask Question", {
                question: query,
            });

            if (data) {
                query = `${query} 
                \n\n
                User asked to attach the following data:
                \n
                Date Range: ${data.dateRange}
                \`\`\`
                ${JSON.stringify(dataToAttach, null, 2)}
                \`\`\`
                `;
            }

            socket.emit(
                "message",
                JSON.stringify({
                    model,
                    requestId: newRequestId,
                    accountId,
                    userNickName: userNickName,
                    prompt: query,
                }),
            );
        } else {
            console.log("Socket.io is not connected. Wait for the connection to be established.");
        }
    };

    const handleKeyDown = (event, inputValue) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(inputValue);
        }
    };

    const samplePrompts = getSamplePrompts({});

    useEffect(() => {
        const intercomButton = document.querySelector(".intercom-lightweight-app-launcher");
        if (intercomButton) {
            intercomButton.style.display = isOpen ? "none" : "block";
        }

        if (isOpen) {
            setTimeout(() => {
                setVisible(true);
            }, 100);
        } else {
            setTimeout(() => {
                setVisible(false);
            }, 100);
        }
    }, [isOpen]);

    useEffect(() => {
        if (middleRef.current) {
            middleRef.current.scrollTop = middleRef.current.scrollHeight;
        }
    }, [conversation]);

    useEffect(() => {
        if (dataToAttach && !isEmpty(dataToAttach) && socketIsReady) {
            const query = `What do you see in the data? I need help analyzing this data.`;
            handleSubmit(query, dataToAttach);
        }
    }, [dataToAttach, socketIsReady]);

    function CustomLink({ href, children }) {
        const [visible, setVisible] = useState(true);
        if (href === "#sample_prompt") {
            if (!visible) {
                return null;
            }

            return (
                <sample
                    onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(children?.[0]);
                        setVisible(false);
                    }}
                >
                    {children}
                </sample>
            );
        }

        // Check if the link is internal
        if (href.startsWith("/dashboard")) {
            return (
                <span
                    onClick={(e) => {
                        e.preventDefault();
                        // For React Router v5
                        history.push(href);
                    }}
                    style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                >
                    {children}
                </span>
            );
        } else {
            // For external links, render a normal anchor tag
            return (
                <a href={href} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            );
        }
    }

    const Content = ({ conversation = [] }) => {
        if (conversation.length === 0) {
            return (
                <div className={style.samplesWrapper}>
                    <h1>Welcome to Your AI Marketing Assistant!</h1>
                    <p>
                        Our chatbot is ready to provide data-driven insights and visualizations to help you optimize
                        your marketing efforts. Just ask a question, and the AI will retrieve the necessary data,
                        creating charts and summaries as needed.
                    </p>
                    <div className={style.samplesContainer}>
                        {samplePrompts.map((item) => (
                            <div
                                key={item.id}
                                className={style.sample}
                                onClick={() => {
                                    setChatInput(item.value);
                                    handleSubmit(item.value);
                                }}
                            >
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <div className={style.conversation}>
                {conversation.map((message) => (
                    <div key={message.id} className={style.message}>
                        {message.sender === "user" ? (
                            <div className={style.userMessage}>
                                <Avatar src={userPicture} size={24} />
                                <div>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.content}</ReactMarkdown>
                                </div>
                            </div>
                        ) : (
                            <div className={style.assistantMessage}>
                                <Avatar src={IconChatGPTSVG} className={style.avatar} size={24} />
                                <div>
                                    {message.content ? (
                                        <ReactMarkdown remarkPlugins={[remarkGfm]} components={{ a: CustomLink }}>
                                            {format(message.content, accountId, handleSubmit)}
                                        </ReactMarkdown>
                                    ) : (
                                        <ThreeDots color="#444C59" style={{ marginTop: 4 }} />
                                    )}
                                    {message.attachment && (
                                        <div className={style.attachment}>
                                            {message.attachment.contentType.startsWith("image/") ? (
                                                <img
                                                    src={message.attachment.dataUrl}
                                                    alt={message.attachment.filename}
                                                    style={{ maxWidth: "100%" }}
                                                    onClick={handleOpenInNewTab(message.attachment.dataUrl)}
                                                />
                                            ) : (
                                                <a
                                                    href={message.attachment.dataUrl}
                                                    download={message.attachment.filename}
                                                >
                                                    Download {message.attachment.filename}
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    const handleOpenInNewTab = (base64String) => () => {
        // Create a Blob from the base64 string
        const base64Data = base64String.split(",")[1]; // Extract base64 content
        const contentType = base64String.split(";")[0].split(":")[1]; // Extract content type
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });

        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);

        // Open the URL in a new tab
        window.open(blobUrl, "_blank");
    };

    return (
        <div className={`${style.wrapper} ${visible ? style.open : style.closed}`}>
            {!socketIsReady ? (
                <div className={style.headerContainer}>
                    <div className={style.headerTitle}>
                        <IconChatGPT />
                        Connecting to ChatGPT
                        <ThreeDots color="#444C59" />
                    </div>
                </div>
            ) : (
                <React.Suspense fallback={<ThreeDots color="#444C59" />}>
                    <div className={style.headerContainer}>
                        <div className={style.headerTitle}>
                            <IconChatGPT />
                            Ask ChatGPT
                        </div>
                        <Button
                            secondary
                            small
                            onClick={() => {
                                setChatInput("");
                                setConversation([]);
                                setCurrentRequestId(null);
                            }}
                        >
                            Clear Chat
                        </Button>
                        <span
                            className={style.closeIcon}
                            secondary
                            small
                            onClick={() => {
                                dispatch(updateChatbot({ isOpen: false }));
                            }}
                        >
                            <CloseIcon />
                        </span>
                    </div>
                    <div className={style.middle} ref={middleRef}>
                        <Content conversation={conversation} />
                    </div>
                    <div className={style.bottom}>
                        <TextareaAutosize
                            name="textValue"
                            value={chatInput}
                            maxRows={3}
                            style={{ resize: "none", overflow: "hidden" }}
                            onChange={(e) => setChatInput(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, chatInput)}
                            placeholder="Ask a question to our AI…"
                        />
                        <Button disabled={!chatInput} onClick={() => handleSubmit(chatInput)}>
                            {isMobile ? <SendIcon /> : "Ask"}
                        </Button>
                    </div>
                </React.Suspense>
            )}
        </div>
    );
};

const format = (data, accountId) => {
    if (!data) return "";

    return data
        .replace(
            /\[\[(.*?)\]\]/g,
            (match, sample_prompt) => `[${sample_prompt.replace("[[", "").replace("]]", "")}](#sample_prompt)`,
        )
        .replace(/Analysis Feature/g, `[Analysis Feature](/dashboard/${accountId}/analysis)`)
        .replace(/Report Builder Feature/g, `[Report Builder Feature](/dashboard/${accountId}/kpi/report-builder)`)
        .replace(/Insights Feature/g, `[Insights Feature](/dashboard/${accountId}/insights)`)
        .replace(
            /Industry Benchmarking Feature/g,
            `[Industry Benchmarking Feature](/dashboard/${accountId}/benchmarks)`,
        )
        .replace(
            /Enhanced Budget Monitoring Feature/g,
            `[Enhanced Budget Monitoring Feature](/dashboard/${accountId}/enhanced-budgeting)`,
        )
        .replace(/Integration Settings/g, `[Integration Settings](/dashboard/${accountId}/settings/integrations)`)
        .replace(/\n/g, "  \n");
};
