import React from "react";
import ColourCard from "../ColourCard";
import Avatar from "components/Avatar";
import Button from "../Button";
import style from "./style.module.scss";
import WarningIcon from "assets/images/icons/Icon-Alert.svg";

export default ({ title, subtitle, buttons = [] }) => (
    <ColourCard className={style.competitorsWarning}>
        <Avatar className={style.competitorsWarningAvatar} size={48} src={WarningIcon} />
        <div>
            <h3>{title}</h3>
            <p>{subtitle}</p>
            <div className={style.buttons}>
                {buttons.map(({ label, onClick }) => (
                    <Button medium key={label} onClick={onClick} className={style.competitorsWarningButton}>
                        {label}
                    </Button>
                ))}
            </div>
        </div>
    </ColourCard>
);
