import React from "react";
import { Collapse, TableCell, TableRow } from "@material-ui/core";
import Carret from "assets/images/icons/JSX/Down-arrow-icon";
import classNames from "classnames";
import Checkbox from "components/Checkbox";
import ThreeDots from "components/Loading/ThreeDots";
import style from "./style.module.scss";

const Row = ({
    row,
    headCells,
    hasHover,
    rowClass,
    verticalAlign: overallVerticalAlign,
    isScrolled = false,
    parentScrolled = false,
    orderBy,
    order,
    isLastRow = false,
    index,
    activeMetricFilter,
    heatMapSettings = {},
    id,
    selected = [],
    handleClick,
    isItemSelected,
    showSelection,
}) => {
    const [open, setOpen] = React.useState(false);
    const CollapsedComponent = row._collapsedComponent || null;

    return (
        <>
            <TableRow
                selected={isItemSelected}
                hover={hasHover}
                tabIndex={-1}
                className={classNames(rowClass, {
                    hasCollapsedComponent: CollapsedComponent,
                    collapsedOpen: open,
                    isLastRow: isLastRow,
                })}
            >
                {showSelection && (
                    <TableCell
                        style={{
                            borderRight: "1px solid #eaf0f7",
                            padding: "0 0.875rem",
                            minWidth: "3.125rem",
                        }}
                        className={`${row?.className || ""}`}
                    >
                        <Checkbox
                            input={{
                                onChange: (event) => handleClick(event, id),
                                checked: isItemSelected,
                                inputprops: { "aria-labelledby": id },
                            }}
                            hideLabel
                            className={style.checkbox}
                        />
                    </TableCell>
                )}
                {Object.keys(headCells).map((key) => {
                    const filterCells = headCells[key] || {};

                    const {
                        width = "auto",
                        minWidth = 0,
                        expandContentHeight = false,
                        align = "left",
                        padding = "0.75rem 0.875rem",
                        hasDivider = false,
                        freeze = false,
                    } = filterCells || {};

                    const { color, opacity } = activeMetricFilter || {};
                    const heatmap = heatMapSettings[key] || {};
                    const rowContent =
                        row[key]?.isCellLoading === true ? <ThreeDots color="#1b264f" /> : row[key]?.content;

                    return (
                        <TableCell
                            width={width}
                            align={align}
                            padding="none"
                            style={{
                                transition: "background-color 0.5s ease",
                                maxWidth: width,
                                minWidth,
                                backgroundColor: row[key]?.analysisTrendColor || row[key]?.backgroundColor || "",
                                padding: padding,
                                borderRight: hasDivider ? "1px solid #eaf0f7" : "none",
                                verticalAlign: row[key]?.verticalAlign || overallVerticalAlign,
                                ...(key === activeMetricFilter?.id
                                    ? { backgroundColor: `rgba(${color}, ${opacity})` }
                                    : {}),
                                ...(Object.keys(heatMapSettings).length > 0
                                    ? { backgroundColor: `rgba(${heatmap?.color}, ${heatmap?.opacity})` }
                                    : {}),
                            }}
                            className={`${freeze && "freezeCell"} ${freeze &&
                                (isScrolled || parentScrolled) &&
                                "isScrolled"} ${hasDivider && "hasDivider"} ${expandContentHeight &&
                                "expandContentHeight"} ${row?.className || ""}`}
                            key={key}
                        >
                            {CollapsedComponent && row[key]?.hasDropDown ? (
                                <div className={`collapseToggle ${open && "open"}`} onClick={() => setOpen(!open)}>
                                    <Carret />
                                    <span>{rowContent}</span>
                                </div>
                            ) : (
                                rowContent
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
            {CollapsedComponent && (
                <TableRow>
                    <TableCell className="collapsedCell" padding="none" colSpan={Object.keys(headCells).length}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <CollapsedComponent
                                parentScrolled={isScrolled}
                                defaultSortProp={orderBy}
                                defaultSortOrder={order}
                            />
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default React.memo(Row);
