import MaterialUITable from "components/Table/MaterialUITable";
import TableWrapper from "components/Table/MaterialUITable/TableWrapper";
import { calculateGrowth, toPrettyNumber, toTwoDecimalPlaces } from "lib/metricCalculations";
import { GrowthNumber, metricFriendlyName } from "lib/utility";
import moment from "moment";
import React from "react";
import style from "./style.module.scss";
import BenchmarkingChart from "components/BenchmarkingChart";
import classNames from "classnames";
import { isEmpty } from "lodash";
import Button from "components/Button";

export const tableHeadCells = (months) => [
    {
        id: "platform",
        label: "Platform & Network",
        sortable: true,
        headPadding: "1rem 0.625rem 1rem 1.25rem",
        padding: "0",
        width: "436px",
        freeze: true,
        hasDivider: true,
    },
    ...months.map((month) => ({
        id: month,
        label: moment(month).format("MMMM YYYY"),
        padding: "0",
        width: "300px",
        align: "right",
        hasDivider: true,
    })),
];

export const subTableHeadCells = (id) => {
    return [
        {
            id: id ? id : "platform",
            label: null,
            headPadding: "1rem 0.875rem 1rem 1.25rem",
            padding: "0",
            width: "180px",
        },
    ];
};

export const getTotalRow = ({ metric, secondaryMetric, months, budgetPlannerData, showOnly }) => ({
    id: "total",
    platform: {
        content: (
            <div className={style.subtableRowPlatformContent}>
                <div className={style.rowPlatformContentLabel}>
                    <h4>Total</h4>
                </div>
                <TableWrapper className={style.subTableContainer}>
                    <MaterialUITable
                        headCells={subTableHeadCells()}
                        withHeaders={false}
                        rowClass={style.rowClassSubTable}
                        rows={[
                            {
                                id: "recommendedBudget",
                                platform: {
                                    content: (
                                        <div className={classNames(style.subTablePlatformCellLabel, style.isHeadCell)}>
                                            Month Budget
                                        </div>
                                    ),
                                    value: "budget",
                                    verticalAlign: "top",
                                },
                            },
                            {
                                id: "metricValue",
                                platform: {
                                    content: (
                                        <div className={style.subTablePlatformCellLabel}>
                                            Predicted {metricFriendlyName(metric)}
                                        </div>
                                    ),
                                    value: metric,
                                    verticalAlign: "top",
                                },
                            },
                            {
                                id: "secondaryMetricValue",
                                platform: {
                                    content: (
                                        <div className={style.subTablePlatformCellLabel}>
                                            Predicted {metricFriendlyName(secondaryMetric)}
                                        </div>
                                    ),
                                    value: secondaryMetric,
                                    verticalAlign: "top",
                                },
                            },
                        ].filter(({ id }) => isEmpty(showOnly) || showOnly.includes(id))}
                    />
                </TableWrapper>
            </div>
        ),
        value: "total",
        verticalAlign: "middle",
    },
    ...months.reduce((acc, month) => {
        // total revenue original and updated
        let totalSecondaryValue = 0;
        let totalOriginalSecondaryValue = 0;
        let totalOriginalForecastedBudget = 0;
        let totalBudget = 0;
        (budgetPlannerData?.[month] || []).forEach((item) => {
            totalSecondaryValue += item?.secondaryMetricValue;
            totalOriginalSecondaryValue += item?.forecasted?.secondaryMetricValue;
            totalOriginalForecastedBudget += item?.forecasted?.cost;
            totalBudget += item?.budget;
        });

        const updatedMetricValue = ["roas"].includes(metric)
            ? (100 * totalSecondaryValue) / totalBudget
            : totalBudget / totalSecondaryValue;

        return {
            ...acc,
            [month]: {
                content: (
                    <TableWrapper className={style.subTableContainer}>
                        <MaterialUITable
                            headCells={subTableHeadCells(month)}
                            withHeaders={false}
                            rowClass={style.rowClassSubTable}
                            rows={[
                                {
                                    id: "recommendedBudget",
                                    [month]: {
                                        content: (
                                            <div className={classNames(style.subTableCellLabel, style.isHeadCell)}>
                                                {toPrettyNumber(totalBudget, "budget")}
                                            </div>
                                        ),
                                        value: totalBudget,
                                        verticalAlign: "top",
                                    },
                                },
                                {
                                    id: "metricValue",
                                    [month]: {
                                        content: (
                                            <div className={style.subTableCellLabel}>
                                                {toPrettyNumber(updatedMetricValue || 0, metric)}
                                            </div>
                                        ),
                                        value: updatedMetricValue,
                                        verticalAlign: "top",
                                    },
                                },
                                {
                                    id: "secondaryMetricValue",
                                    [month]: {
                                        content: (
                                            <div className={style.subTableCellLabel}>
                                                {toPrettyNumber(totalSecondaryValue, secondaryMetric)}
                                            </div>
                                        ),
                                        value: totalSecondaryValue,
                                        verticalAlign: "top",
                                    },
                                },
                            ].filter(({ id }) => isEmpty(showOnly) || showOnly.includes(id))}
                        />
                    </TableWrapper>
                ),
                value: { totalBudget, updatedMetricValue, totalSecondaryValue },
                verticalAlign: "top",
            },
        };
    }, {}),
});

export const getChartData = ({ uiChartData, metric }) =>
    uiChartData.map((item) => {
        const [costValue, metricValue] = item;
        return {
            cost: costValue,
            [metric]: metricValue,
        };
    });

export const getBudgetPlannerSubTableRow = ({
    month,
    metric,
    secondaryMetric,
    metricValue,
    secondaryMetricValue,
    min,
    max,
    budget,
    showOnly,
    totalBudget,
    saveGoalsData,
    metrics,
    goals,
    platform,
    accountId,
    budgetSavePending,
    setBudgetSavePending,
}) => {
    const hideBenchmarkingChart = min === 0 && max === 0;

    return [
        {
            id: "recommendedBudget",
            [month]: {
                content: (
                    <div className={classNames(style.subTableCellLabel, style.isHeadCell, style.budget)}>
                        <Button
                            small
                            hollow
                            disabled={budgetSavePending[`${month}-${platform}`]}
                            onClick={() => {
                                const { [`${month}-01`]: monthGoals = {} } = goals;
                                const newMetrics = {
                                    ...metrics,
                                    [`${platform}Budget`]: {
                                        active: true,
                                    },
                                };

                                const newGoals = {
                                    ...monthGoals,
                                    [`${platform}Budget`]: {
                                        value: toTwoDecimalPlaces(budget),
                                    },
                                };

                                setBudgetSavePending({
                                    [`${month}-${platform}`]: true,
                                });

                                return saveGoalsData({
                                    accountId,
                                    goals: newGoals,
                                    metrics: newMetrics,
                                    date: `${month}-01`,
                                    carryOver: false,
                                }).then(() => {
                                    setBudgetSavePending({
                                        [`${month}-${platform}`]: false,
                                    });
                                });
                            }}
                        >
                            Set Budget
                        </Button>
                        <div>
                            <div style={{ display: "flex", justifyContent: "right", gap: 4, height: 14 }}>
                                <strong>{toPrettyNumber(budget, "cost")}</strong>
                            </div>
                            <small style={{ align: "right", color: "#666", fontSize: 10 }}>
                                {toTwoDecimalPlaces((100 * budget) / totalBudget, "percent")}% of Total Budget
                            </small>
                        </div>
                    </div>
                ),
                value: metricValue,
                verticalAlign: "top",
            },
        },
        {
            id: "metricValue",
            [month]: {
                content: <div className={style.subTableCellLabel}>{toPrettyNumber(metricValue, metric)}</div>,
                value: metricValue,
                verticalAlign: "top",
            },
        },
        {
            id: "secondaryMetricValue",
            [month]: {
                content: (
                    <div className={style.subTableCellLabel}>
                        {toPrettyNumber(secondaryMetricValue, secondaryMetric)}{" "}
                    </div>
                ),
                value: secondaryMetricValue,
                verticalAlign: "top",
            },
        },
        {
            id: "benchmarkingChart",
            [month]: {
                content: hideBenchmarkingChart ? (
                    <div className={style.subTableCellLabel}>{toPrettyNumber(metricValue, metric)}</div>
                ) : (
                    <BenchmarkingChart
                        lessIsBetter={["roas"].includes(metric) ? false : true}
                        min={0}
                        max={Math.max(max * 1.5, metricValue)}
                        metric={metric}
                        value={metricValue}
                        benchmarkMin={min}
                        benchmarkMax={max}
                        positionY={-110}
                    />
                ),
                value: { min, max },
                verticalAlign: "top",
            },
        },
    ].filter(({ id }) => isEmpty(showOnly) || showOnly.includes(id));
};
