const GET_REPORT_BUILDER_BENCHMARK_DATA = {
    adwords: {
        "industryBenchmark-none": "GET_REPORT_BUILDER_ADWORDS_BENCHMARK_NONE_DATA",
        "industryBenchmark-mediaSpend": "GET_REPORT_BUILDER_ADWORDS_BENCHMARK_SPEND_DATA",
        "industryBenchmark-aov": "GET_REPORT_BUILDER_ADWORDS_BENCHMARK_AOV_DATA",
        "industryBenchmark-location": "GET_REPORT_BUILDER_ADWORDS_BENCHMARK_LOCATION_DATA",
    },
    facebook: {
        "industryBenchmark-none": "GET_REPORT_BUILDER_FACEBOOK_BENCHMARK_NONE_DATA",
        "industryBenchmark-mediaSpend": "GET_REPORT_BUILDER_FACEBOOK_BENCHMARK_SPEND_DATA",
        "industryBenchmark-aov": "GET_REPORT_BUILDER_FACEBOOK_BENCHMARK_AOV_DATA",
        "industryBenchmark-location": "GET_REPORT_BUILDER_FACEBOOK_BENCHMARK_LOCATION_DATA",
    },
    shopify: {
        "industryBenchmark-none": "GET_REPORT_BUILDER_SHOPIFY_BENCHMARK_NONE_DATA",
        "industryBenchmark-mediaSpend": "GET_REPORT_BUILDER_SHOPIFY_BENCHMARK_SPEND_DATA",
        "industryBenchmark-aov": "GET_REPORT_BUILDER_SHOPIFY_BENCHMARK_AOV_DATA",
        "industryBenchmark-location": "GET_REPORT_BUILDER_SHOPIFY_BENCHMARK_LOCATION_DATA",
    },
    bing: {
        "industryBenchmark-none": "GET_REPORT_BUILDER_BING_BENCHMARK_NONE_DATA",
        "industryBenchmark-mediaSpend": "GET_REPORT_BUILDER_BING_BENCHMARK_SPEND_DATA",
        "industryBenchmark-aov": "GET_REPORT_BUILDER_BING_BENCHMARK_AOV_DATA",
        "industryBenchmark-location": "GET_REPORT_BUILDER_BING_BENCHMARK_LOCATION_DATA",
    },
    linkedin: {
        "industryBenchmark-none": "GET_REPORT_BUILDER_LINKEDIN_BENCHMARK_NONE_DATA",
        "industryBenchmark-mediaSpend": "GET_REPORT_BUILDER_LINKEDIN_BENCHMARK_SPEND_DATA",
        "industryBenchmark-aov": "GET_REPORT_BUILDER_LINKEDIN_BENCHMARK_AOV_DATA",
        "industryBenchmark-location": "GET_REPORT_BUILDER_LINKEDIN_BENCHMARK_LOCATION_DATA",
    },
    analyticsV4: {
        "industryBenchmark-none": "GET_REPORT_BUILDER_GA4_BENCHMARK_NONE_DATA",
        "industryBenchmark-mediaSpend": "GET_REPORT_BUILDER_GA4_BENCHMARK_SPEND_DATA",
        "industryBenchmark-aov": "GET_REPORT_BUILDER_GA4_BENCHMARK_AOV_DATA",
        "industryBenchmark-location": "GET_REPORT_BUILDER_GA4_BENCHMARK_LOCATION_DATA",
    },
    tiktok: {
        "industryBenchmark-none": "GET_REPORT_BUILDER_TIKTOK_BENCHMARK_NONE_DATA",
        "industryBenchmark-mediaSpend": "GET_REPORT_BUILDER_TIKTOK_BENCHMARK_SPEND_DATA",
        "industryBenchmark-aov": "GET_REPORT_BUILDER_TIKTOK_BENCHMARK_AOV_DATA",
        "industryBenchmark-location": "GET_REPORT_BUILDER_TIKTOK_BENCHMARK_LOCATION_DATA",
    },
    pinterest: {
        "industryBenchmark-none": "GET_REPORT_BUILDER_PINTEREST_BENCHMARK_NONE_DATA",
        "industryBenchmark-mediaSpend": "GET_REPORT_BUILDER_PINTEREST_BENCHMARK_SPEND_DATA",
        "industryBenchmark-aov": "GET_REPORT_BUILDER_PINTEREST_BENCHMARK_AOV_DATA",
        "industryBenchmark-location": "GET_REPORT_BUILDER_PINTEREST_BENCHMARK_LOCATION_DATA",
    },
};

const GET_REPORT_BING_TOP_TERMS_DATA = "GET_REPORT_BING_TOP_TERMS_DATA";
const SET_SHOW_CARD_SETTINGS_SIDEBAR = "SET_SHOW_CARD_SETTINGS_SIDEBAR";
const SET_EDITING_CARD = "SET_EDITING_CARD";
const SET_TAB_INDEX_SIDEBAR = "SET_TAB_INDEX_SIDEBAR";
const SET_SIDEBAR_INTEGRATION = "SET_SIDEBAR_INTEGRATION";
const SET_SIDEBAR_CARD_TYPE = "SET_SIDEBAR_CARD_TYPE";
const SET_SIDEBAR_SEARCH_INPUT = "SET_SIDEBAR_SEARCH_INPUT";
const GET_ACCOUNT_MULTIPLE_REPORTS = "GET_ACCOUNT_MULTIPLE_REPORTS";
const SET_ACCOUNT_CURRENT_REPORT = "SET_ACCOUNT_CURRENT_REPORT";
const UPDATE_ACCOUNT_REPORT_SETTINGS = "UPDATE_ACCOUNT_REPORT_SETTINGS";
const RENAME_ACCOUNT_REPORT = "RENAME_ACCOUNT_REPORT";
const SET_LOAD_REPORT_DATA = "SET_LOAD_REPORT_DATA";
const SET_SHOW_REPORT_SETTINGS_SIDEBAR = "SET_SHOW_REPORT_SETTINGS_SIDEBAR";
const DELETE_ACCOUNT_REPORT = "DELETE_ACCOUNT_REPORT";
const DUPLICATE_ACCOUNT_REPORT = "DUPLICATE_ACCOUNT_REPORT";
const CREATE_EMPTY_ACCOUNT_REPORT = "CREATE_EMPTY_ACCOUNT_REPORT";
const CLEAR_REPORT_BUILDER_DATA_LOADING = "CLEAR_REPORT_BUILDER_DATA_LOADING";

export default {
    GET_REPORT_BUILDER_BENCHMARK_DATA,
    GET_REPORT_BING_TOP_TERMS_DATA,
    SET_SHOW_CARD_SETTINGS_SIDEBAR,
    SET_EDITING_CARD,
    SET_TAB_INDEX_SIDEBAR,
    SET_SIDEBAR_INTEGRATION,
    SET_SIDEBAR_CARD_TYPE,
    SET_SIDEBAR_SEARCH_INPUT,
    GET_ACCOUNT_MULTIPLE_REPORTS,
    SET_ACCOUNT_CURRENT_REPORT,
    UPDATE_ACCOUNT_REPORT_SETTINGS,
    RENAME_ACCOUNT_REPORT,
    SET_LOAD_REPORT_DATA,
    SET_SHOW_REPORT_SETTINGS_SIDEBAR,
    DELETE_ACCOUNT_REPORT,
    DUPLICATE_ACCOUNT_REPORT,
    CREATE_EMPTY_ACCOUNT_REPORT,
    CLEAR_REPORT_BUILDER_DATA_LOADING,
};
