import types from "./types";

export const updateReportUi = (ui) => {
    return {
        type: types.UPDATE_REPORT_UI,
        payload: {
            ui,
        },
    };
};

export const updateReportUiOrder = (order) => ({
    type: types.UPDATE_REPORT_UI_ORDER,
    payload: {
        order,
    },
});

export const clearReportUiSubFilter = ({ filterName, property }) => ({
    type: types.CLEAR_REPORT_UI_FILTER,
    filterName,
    property,
});

export const toggleCompareDataLoading = (isLoading) => ({
    type: types.TOGGLE_COMPARE_DATA_LOADING,
    payload: {
        isLoading,
    },
});

export const toggleRecentlySelectedLoading = ({ metrics, recentlySelected = false }) => ({
    type: types.LOADING_RECENTLY_SELECTED,
    payload: {
        metrics,
        recentlySelected,
    },
});

export const clearGoalData = () => ({
    type: types.CLEAR_GOAL_DATA,
});

export const clearGoalDataLoading = () => ({
    type: types.CLEAR_REPORT_BUILDER_DATA_LOADING,
});

export const toggleMetricSelection = () => ({
    type: types.TOGGLE_METRIC_SELECTION,
});

export const setReportUiPending = (reportUiPending) => ({
    type: types.SET_REPORTING_UI_PENDING,
    payload: reportUiPending,
});

export const setSelectedNoteId = (payload) => ({
    type: types.SET_SELECTED_NOTE_ID,
    payload,
});

export const setCreateNoteDialogVisible = (payload) => ({
    type: types.SET_CREATE_NOTE_DIALOG_VISIBLE,
    payload,
});

export const setCampaignGroupPerformance = (payload) => ({
    type: types.SET_CAMPAIGN_GROUP_PERFORMANCE,
    payload,
});

export const setDisabledImproveBenchmark = (disabledImproveBenchmark) => ({
    type: types.UPDATE_DISABLE_IMPROVE_BENCHMARK,
    payload: disabledImproveBenchmark,
});

export const setEditingCampaign = (campaignGroupId, platform, campaignId) => ({
    type: types.SET_EDITING_CAMPAIGN,
    payload: {
        campaignGroupId,
        platform,
        campaignId,
    },
});

export const setShowEditCampaignModal = (showEditCampaignModal) => ({
    type: types.SET_SHOW_EDIT_CAMPAIGN_MODAL,
    payload: showEditCampaignModal,
});

export const toggleGoalSettingsDrawer = (isOpen) => ({
    type: types.TOGGLE_GOAL_SETTINGS_DRAWER,
    isOpen,
});
