import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/Button";
import { CloseDialog, DialogVFour } from "components/Dialog";
import CheckboxSearch from "components/SearchBox/CheckboxSearchWithComponent";
import { unionBy } from "lodash";
import { useForm, useWatch } from "react-hook-form";
import { filterHeadCells, getColumnOptions, getInitialColumns } from "./helpers";
import { columnsSchema } from "./schema";
import SelectedColumns from "./SelectedColumns";
import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectAccountId } from "_redux/sites/selectors";
import { updateTableSettings } from "_redux/sites";

const ColumnSettingsDialog = ({ isOpen, onClose, headCells, tableId, columnsSetting = [], fixedColumns = [] }) => {
    const accountId = useSelector(makeSelectAccountId());
    const filteredHeadCells = filterHeadCells({ headCells, fixedColumns });
    const dispatch = useDispatch();

    const {
        control,
        setValue,
        reset,
        formState: { isValid, isSubmitting, isDirty },
        handleSubmit,
    } = useForm({
        shouldUnregister: true,
        resolver: yupResolver(columnsSchema),
    });

    useEffect(() => {
        reset({
            selectedColumns: getInitialColumns({ columnsSetting, headCells: filteredHeadCells }),
        });
    }, [columnsSetting, headCells, isOpen]);

    const selectedColumns = useWatch({ control, name: "selectedColumns" });
    const columnOptions = getColumnOptions({ selectedColumns, headCells: filteredHeadCells });

    const handleChangeColumns = (updatedColumns) => {
        setValue("selectedColumns", updatedColumns, { shouldValidate: true, shouldDirty: true });
    };

    const handleColumnToggle = ({ option, isChecked }) => {
        const updatedColumns = isChecked
            ? [...selectedColumns, option]
            : selectedColumns.filter((item) => item.id !== option.id);
        handleChangeColumns(updatedColumns);
    };

    const handleSelectAll = ({ options, isChecked }) => {
        const updatedColumns = isChecked
            ? unionBy(
                  selectedColumns,
                  options.map((option) => ({ ...option, checked: true })),
                  "id",
              )
            : selectedColumns.filter((item) => !options.some((option) => option.id === item.id));

        handleChangeColumns(updatedColumns);
    };

    const handleFormSubmit = async () => {
        const transformedColumns = selectedColumns.map((column, index) => ({
            id: column.id,
            order: index,
        }));

        await dispatch(updateTableSettings({ accountId, tableSettings: transformedColumns, tableId }));
        onClose();
    };

    return (
        <DialogVFour
            open={isOpen}
            classes={{
                rootClass: style.metricSettingsRoot,
                paperClass: style.metricSettingsPaper,
            }}
        >
            <CloseDialog closeActions={onClose} />
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className={style.metricSettings}>
                    <div className={style.heading}>
                        <h3>Column Settings</h3>
                        <p>Select columns to appear on your table.</p>
                    </div>
                    <div className={style.metricContent}>
                        <div className={style.metricAddContainer}>
                            <span className={style.selectText}>Select Columns</span>

                            <CheckboxSearch
                                className={style.metricCheckboxes}
                                search={{
                                    accessor: "label",
                                    placeholder: "Search Columns",
                                }}
                                options={columnOptions}
                                onChange={(option) => handleColumnToggle({ option, isChecked: option.checked })}
                                selectAllTxt="Select All Columns"
                                itemType="Columns"
                                onChangeAll={(options, isChecked) => handleSelectAll({ options, isChecked })}
                                sortFn={() => null}
                                lazyLoad
                            />
                        </div>

                        <SelectedColumns
                            selectedColumns={selectedColumns}
                            onColumnToggle={handleColumnToggle}
                            onChangeColumns={handleChangeColumns}
                        />
                    </div>
                    <div className={style.buttons}>
                        <Button medium type="submit" disabled={!isDirty || isSubmitting || !isValid}>
                            {isSubmitting ? "Saving..." : "Save"}
                        </Button>
                        <Button medium secondary onClick={onClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </DialogVFour>
    );
};

export default ColumnSettingsDialog;
