const ORGANIC_INSTAGRAM_INSIGHT_REPORT = "ORGANIC_INSTAGRAM_INSIGHT_REPORT";
const ORGANIC_INSTAGRAM_DEMOGRAPHICS = "ORGANIC_INSTAGRAM_DEMOGRAPHICS";
const ORGANIC_INSTAGRAM_TOP_POSTS = "ORGANIC_INSTAGRAM_TOP_POSTS";
const CLEAR_ORGANIC_INSTAGRAM_DATA_LOADING = "CLEAR_ORGANIC_INSTAGRAM_DATA_LOADING";

export default {
    ORGANIC_INSTAGRAM_INSIGHT_REPORT,
    ORGANIC_INSTAGRAM_DEMOGRAPHICS,
    ORGANIC_INSTAGRAM_TOP_POSTS,
    CLEAR_ORGANIC_INSTAGRAM_DATA_LOADING,
};
