import createReducer from "../../../lib/createReducer";

import { handle } from "redux-pack";
import types from "./types";
import moment from "moment";

const defaultState = {
    link: null,
    decodeError: false,
    decodePending: true,
    pdfBlob: null,
    pdfPending: false,
    pdfError: false,
    allLinks: [],
};

export default createReducer(defaultState, {
    [types.GET_SHAREABLE_REPORT_LINK]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                linkPending: true,
                linkError: false,
            }),
            failure: (prevState) => ({
                ...prevState,
                linkError: true,
            }),
            success: (prevState) => ({
                ...prevState,
                link: action.payload.data.link,
                allLinks: [
                    ...prevState.allLinks,
                    {
                        id: action.payload.data.id,
                        createdBy: action.meta.userId,
                        createdAt: moment().utc(),
                        metadata: action.meta,
                        linkName: action.meta.linkName,
                    },
                ],
            }),
            finish: (prevState) => ({
                ...prevState,
                linkPending: false,
            }),
        }),
    [types.GET_SHAREABLE_REPORT_PDF]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                pdfPending: true,
                pdfError: false,
            }),
            failure: (prevState) => ({
                ...prevState,
                pdfError: true,
            }),
            success: (prevState) => ({
                ...prevState,
                pdfBlob: action.payload.data,
            }),
            finish: (prevState) => ({
                ...prevState,
                pdfPending: false,
            }),
        }),
    [types.DECODE_SHARED_REPORT_TOKEN]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                decodeError: false,
                decodePending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                decodeError: true,
            }),
            success: (prevState) => ({
                ...prevState,
                ...action.payload.data,
            }),
            finish: (prevState) => ({
                ...prevState,
                decodePending: false,
            }),
        }),
    [types.GET_ALL_SHAREABLE_REPORT_LINK]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                allLinks: [],
            }),
            success: (prevState) => {
                return {
                    ...prevState,
                    allLinks: action.payload.data,
                };
            },
        }),
    [types.REMOVE_SHAREABLE_REPORT_LINK]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                allLinks: prevState.allLinks.filter(({ id } = {}) => id !== action.meta.id),
            }),
        }),
    [types.UPDATE_SHAREABLE_REPORT_LINK]: (state, action) =>
        handle(state, action, {
            success: (prevState) => {
                const updatedItem = prevState.allLinks.find(({ id } = {}) => id === action.meta.id);
                return {
                    ...prevState,
                    allLinks: [
                        {
                            ...updatedItem,
                            ...action.meta,
                        },
                        ...prevState.allLinks.filter(({ id } = {}) => id !== action.meta.id),
                    ],
                };
            },
        }),
});
